import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {CallbackComponent} from './callback.component';



@NgModule({
  declarations: [ CallbackComponent ],
  imports: [
    RouterModule.forRoot([
      {
        path: 'callback',
        pathMatch: 'full',
        component: CallbackComponent
      },
    ])
  ],
  exports: [ RouterModule ]
})
export class CallbackModule { }
