import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IResourceState } from '../../models/resources/instructor-resource.model';
import { InstructorResourcesService } from '../../services/instructor-resources/instructor-resources.service';

@Component({
  selector: 'app-instructor-resources',
  templateUrl: './instructor-resources.component.html',
  styleUrls: ['./instructor-resources.component.scss']
})
export class InstructorResourcesComponent implements OnChanges {

  @Input() instructorResources: IResourceState[];
  @Input() instructorResourceFailed: boolean;

  constructor(private instructorResourceSerice: InstructorResourcesService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.instructorResources = this.instructorResourceSerice.getResourcesWithIcon(this.instructorResources);
  }

}
