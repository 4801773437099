import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICourseProductInformation } from '../../../models/simulation/scores/simulation-results';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-simulation-modules-legend',
  templateUrl: './simulation-modules-legend.component.html',
  styleUrls: ['./simulation-modules-legend.component.scss']
})
export class SimulationModulesLegendComponent  {

  @Input() courseProductInformation: ICourseProductInformation;
  @Input() isShown: boolean;
  @Output() isShownChange: EventEmitter<boolean> = new EventEmitter<boolean>();

}
