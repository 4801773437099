import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ICourseProductTemplate, ICourseProductTemplateElement } from '@stukent/mimic-core';
import { min } from 'moment-timezone';
import { IModule } from 'src/app/models/module.model';
import { IElementSettings } from 'src/app/models/scorable-items/scorable-items-state.model';
import { ICourseSettings, IPeerReviewTableRow } from 'src/app/models/simulation/simulation-settings.model';

@Component({
  selector: 'app-peer-review-settings',
  templateUrl: './peer-review-settings.component.html',
  styleUrls: ['./peer-review-settings.component.scss']
})
export class PeerReviewSettingsComponent implements OnInit, OnChanges {
  @Input() courseProductTemplate: ICourseProductTemplate;
  @Input() elementSettings: IElementSettings[];
  @Input() courseSettings: ICourseSettings;
  @Input() modules: IModule[];
  @Input() peerReviewTableRows: IPeerReviewTableRow[];
  @Input() peerReviewDateErrorMessage: string;

  enablePeerReviewSettings = false;
  warningModal = false;
  showPeerReviewSettings = false;
  allowEarlyFeedback = false;

  lastSelectedDate: Date;
  elementsToUpdate = [];


  @Output() elementSettingsChanged = new EventEmitter<IElementSettings[]>();
  @Output() rowChanged = new EventEmitter<IPeerReviewTableRow>();
  @Output() courseSettingsChanged = new EventEmitter<ICourseSettings>();

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    if (this.courseSettings) {
      this.courseSettings = JSON.parse(JSON.stringify(this.courseSettings));
      this.enablePeerReviewSettings = this.courseSettings.allowPeerReviewFeedbackDueDates;
    }

    if (this.courseSettings?.allowEarlyDistribution) {
      this.allowEarlyFeedback = true;
    }

  }

  handleToggle() {
    if (this.enablePeerReviewSettings) {
      this.warningModal = true;
    } else {
      this.showPeerReviewSettings = false;
      this.courseSettings.allowPeerReviewFeedbackDueDates = false;
      this.courseSettingsChanged.emit(this.courseSettings);
    }
  }

  handleOkay(): void {
    this.warningModal = false;
    this.showPeerReviewSettings = true;
    this.courseSettings.allowPeerReviewFeedbackDueDates = true;
    this.courseSettingsChanged.emit(this.courseSettings);
  }

  handleCancel(): void {
    this.warningModal = false;
    this.enablePeerReviewSettings = false;
  }

  handleAllowEarlyFeedback(): void {
    this.courseSettings.allowEarlyDistribution = this.allowEarlyFeedback;
    this.courseSettingsChanged.emit(this.courseSettings);
  }

  changedDueDate(newDate: Date, row: IPeerReviewTableRow) {
    const copyRow = JSON.parse(JSON.stringify(row)) as IPeerReviewTableRow;
    if (this.lastSelectedDate === newDate && this.lastSelectedDate !== null) {
      return;
    }
    newDate = (newDate < new Date() && newDate != null) ? new Date() : newDate;
    copyRow.endDate = newDate;

    if (newDate) {
      newDate.setSeconds(0, 0);
    }

    const module = this.modules.find(m => m.id === copyRow.moduleId);
    if (Date.parse(copyRow?.endDate?.toString()) < Date.parse(module?.settings?.startDate?.toString())) {
      copyRow.error = true;
      this.peerReviewDateErrorMessage = 'End date can not be before the start date of the round';
    } else {
      this.peerReviewDateErrorMessage = null;
      copyRow.error = false;
    }

    this.lastSelectedDate = newDate;

    this.rowChanged.emit(copyRow);
  }



  trackById(row: IPeerReviewTableRow) {
    return row.id;
  }
}
