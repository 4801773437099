import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, SecurityContext } from '@angular/core';

import { InstrcutorPortalComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, scoringReducer } from './reducers/root';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MainRoutingModule } from './main-routing/main-routing.module';
import { InstructorPortalConfigurationEffects } from './reducers/config/config.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CallbackModule } from './pages/callback/callback.module';
import { CourseComponent } from './pages/course/course.component';
import { RouterModule } from '@angular/router';
import { EnrollmentEffects } from './reducers/enrollment/enrollment.effects';
import { CourseEffects } from './reducers/course/course.effects';

import {
  en_US,
  NZ_I18N,
  NzIconModule,
  NzModalModule,
  NzTableModule,
  NzTabsModule,
  NzButtonModule,
  NzInputModule,
  NzInputNumberModule,
  NzRadioModule,
  NzDatePickerModule,
  NzSwitchModule,
  NzPopoverModule,
  NzMessageService,
  NzCheckboxModule,
  NzSkeletonModule,
  NzSpinModule,
  NzSelectModule,
  NzAffixModule,
  NzBadgeModule,
  NzTimePickerModule,
  NzToolTipModule,
  NzDropDownModule
} from 'ng-zorro-antd';

import { InstructorPortalScoresEffects } from './reducers/scores/scores.effects';
import { SimulationEffects } from './reducers/simulation/simulation.effects';
import { TemplateEffects } from './reducers/template/template.effects';
import { FormsModule } from '@angular/forms';
import { StudentScoresComponent } from './pages/student-scores/student-scores.component';
import { ElementRendererModule } from '@stukent/elements';
import { allowedModules } from './available-modules';
import {
  BUHICompetitorDataService,
  BUHIDisplayAdImageService,
  BUHIProductsService,
  BUHISearchKeywordsService,
  BUHISocialMediaChannelService,
  BUHISocialMediaMarketDataService,
  ConfigEffects,
  coreConfigReducer,
  ContentElementComponent,
  DownloadElementComponent,
  ImageElementComponent,
  PdfViewerElementComponent,
  MimicCoreModule,
  SupplementalDataService,
  VideoElementComponent
} from '@stukent/mimic-core';
import { CamelToWordsPipe } from './pipes/camel-to-words.pipe';
import { InstructorPortalCoreEffects } from './reducers/core/core.effects';
import { SimulationSettingsComponent } from './pages/simulation-settings/simulation-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { environment } from 'src/environments/environment';
import { LoggerModule } from '@stukent/logger';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { SimulationScoresTableComponent } from './pages/student-scores/simulation-scores/simulation-scores-table/simulation-scores-table.component';
import { SimulationModulesLegendComponent } from './pages/simulation-settings/simulation-modules-legend/simulation-modules-legend.component';
import { GetEnrollmentButtonComponent } from './components/get-enrollment-button/get-enrollment-button.component';
import { StudentEnrollmentsComponent } from './pages/student-enrollments/student-enrollments.component';
import { StudentEnrollmentsTableComponent } from './pages/student-enrollments/student-enrollments-table/student-enrollments-table.component';
import { templateVersionsReducer } from './reducers/templateVersions/templateVersions.reducer';
import { CourseProductTemplateVersionComponent } from './components/version-information/course-product-template-version.component';
import { VersionEffects } from './reducers/templateVersions/templateVersions.effects';
import { MarkdownModule } from 'ngx-markdown';
import { RankingComponent } from './pages/ranking/ranking.component';
import { RankingModule } from '@stukent/ranking';
import { InstructorResourcesEffects } from './reducers/instructor-resource/instructor-resource.effects';
import { InstructorResourcesComponent } from './pages/intructor-resources/instructor-resources.component';
import { InstructorResourceComponent } from './pages/intructor-resources/instructor-resource/instructor-resource.component';
import { SetupInformationComponent } from './pages/setup-information/setup-information.component';
import { SimulationModulesComponent } from './pages/simulation-settings/simulation-modules/simulation-modules.component';
import { ScorableItemsSettingsComponent } from './pages/simulation-settings/scorable-items-settings/scorable-items-settings.component';
import { CourseProductSettingsEffects } from './reducers/scorable-items/scorable-items.effects';
import { ScorableItemsScoresComponent } from './pages/student-scores/scorable-items-scores/scorable-items-scores.component';
import { PeerReviewSettingsComponent } from './pages/simulation-settings/peer-review-settings/peer-review-settings.component';
import { LmsIntegrationEffects } from './reducers/lms-integration/lms-integration.effects';

@NgModule({
  declarations: [
    InstrcutorPortalComponent,
    CourseComponent,
    StudentScoresComponent,
    StudentEnrollmentsTableComponent,
    StudentEnrollmentsComponent,
    CamelToWordsPipe,
    SimulationSettingsComponent,
    SimulationModulesComponent,
    SimulationScoresTableComponent,
    SimulationModulesLegendComponent,
    ScorableItemsSettingsComponent,
    ScorableItemsScoresComponent,
    GetEnrollmentButtonComponent,
    CourseProductTemplateVersionComponent,
    RankingComponent,
    InstructorResourcesComponent,
    InstructorResourceComponent,
    SetupInformationComponent,
    PeerReviewSettingsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
    RouterModule.forRoot([]),

    // Redux
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature('templateVersions', templateVersionsReducer),
    StoreModule.forFeature('appConfiguration', coreConfigReducer),

    StoreModule.forFeature('instructorPortal', reducers),
    StoreModule.forFeature('scores', scoringReducer),

    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
      InstructorPortalConfigurationEffects,
      ConfigEffects,
      EnrollmentEffects,
      InstructorResourcesEffects,
      CourseEffects,
      InstructorPortalScoresEffects,
      SimulationEffects,
      TemplateEffects,
      InstructorPortalCoreEffects,
      VersionEffects,
      CourseProductSettingsEffects,
      LmsIntegrationEffects
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Instructor Portal',
      maxAge: 10,
      logOnly: environment.production
    }),

    // Routing
    MainRoutingModule,
    CallbackModule,
    RankingModule,

    // Ant Design
    NzTabsModule,
    NzTableModule,
    NzModalModule,
    NzIconModule,
    NzButtonModule,
    NzInputModule,
    NzInputNumberModule,
    NzRadioModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzSwitchModule,
    NzPopoverModule,
    NzToolTipModule,
    NzAffixModule,
    NzBadgeModule,
    NzDropDownModule,
    MimicCoreModule.forRoot({
      homeUrl: '',
      isTest: environment.isTest,
      isProduction: environment.production,
      isNext: environment.isNext,
      isInMemory: false,
      configKey: environment.configKey,
      elements: [],
      allowedModules,
      enableAnnotations: false
    }),
    ElementRendererModule.forRoot({
      elements: [ContentElementComponent,
        ContentElementComponent,
        DownloadElementComponent,
        ImageElementComponent,
        PdfViewerElementComponent,
        VideoElementComponent],
      allowedModules
    }),
    LoggerModule.forRoot({
      loggerTypes: environment.isTest ? ['ignore'] : (
        environment.isNext ? ['console'] : ['application insights']
      )
    }),
    NzCheckboxModule,
    NzSkeletonModule,
    NzSpinModule,
    NzSelectModule,
    // Markdown
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    } as any),
  ],
  providers: [
    // Provide Stukent Services here to allow them to cache data
    BUHICompetitorDataService,
    BUHIDisplayAdImageService,
    BUHISocialMediaChannelService,
    BUHISocialMediaMarketDataService,
    BUHISearchKeywordsService,
    BUHIProductsService,
    SupplementalDataService,

    { provide: NZ_I18N, useValue: en_US },
    NzMessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [InstrcutorPortalComponent]
})
export class AppModule {

  public static AlreadyRegisteredElements = false;

  constructor(private injector: Injector) { }

}
