import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { IResetScorableItemRequest } from '../../models/scorable-items/reset-scorable-item-request.model';
import { IScorableItem, IScorableItemConfiguration, IScorableItemResult } from '../../models/scorable-items/scorable-item.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class ScorableItemsService {
  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  getScorableItemsConfiguration(): Observable<IScorableItemConfiguration[]> {
    const { productCode, courseCode } = this.course;

    return this.http.get(`${this.getScoringManagementUrl()}/scorable-items/${productCode}/${courseCode}`).pipe(
      map((r: IScorableItemResult) => r.scorableItems)
    );
  }

  resetScorableItem(scorableItem: IScorableItem): Observable<any> {
    const score = scorableItem.persistedScore;
    const { simulationInstanceId } = score?.context || { simulationInstanceId: null };
    if (!simulationInstanceId) {
      throw new Error('Cannot reset scorable because the score record is invalid');
    }

    const requestBody: IResetScorableItemRequest = {
      scorableItem: scorableItem.scorableItemConfiguration,
      scorableItemIdentifier: {
        courseCode: this.course.courseCode,
        productCode: this.course.productCode,
        studentIdentifier: scorableItem.studentIdentifier,
        instanceId: simulationInstanceId,
      }
    };

    const url = `${this.getScoringManagementUrl()}/scorable-items`;

    return this.http.request('delete', url, { body: requestBody });
  }

  private getScoringManagementUrl(): string {
    const url = this.config?.serviceUrls?.scoringManagement;
    if (!url) {
      throw new Error('Could not get scoring management url from configuration');
    }

    return url;
  }

}
