import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ConfigService } from '../../services/config/config.service';
import { loadInstructorPortalConfig, setInstructorPortalConfig } from './config.actions';

@Injectable()
export class InstructorPortalConfigurationEffects {

  loadInstructorPortalConfig$ = createEffect(() => this.actions$.pipe(
    ofType(loadInstructorPortalConfig),
    mergeMap(({ courseCode, productCode }) =>
      this.configService.get(courseCode, productCode)
        .pipe(
          map(config => setInstructorPortalConfig({ newConfig: config })),
          catchError(() => EMPTY)
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
  ) {
  }
}
