import { Component, Input, OnInit } from '@angular/core';
import { ICourseProductTemplate } from '@stukent/mimic-core';
import { IRankingStudent } from '@stukent/ranking';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  @Input() courseCode: string;
  @Input() productCode: string;
  @Input() url: string;
  @Input() courseProductTemplate: ICourseProductTemplate;
  @Input() students: IRankingStudent[];

  public moduleIds: string[] = [];
  public rankingUrl: string;

  constructor() { }

  ngOnInit(): void {
    this.rankingUrl = this.url;
    if (this.courseProductTemplate.properties?.hasRanking) {
      this.courseProductTemplate.modules.forEach(module => {
        if (!module.properties?.excludeFromResults) {
          this.moduleIds.push(module.id);
        }
      });
    }
  }
}
