import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd';
import { IStudent } from '../../../models/student.model';
import { removeStudentEnrollment } from '../../../reducers/enrollment/enrollment.actions';

@Component({
  selector: 'app-student-enrollments-table',
  templateUrl: './student-enrollments-table.component.html',
  styleUrls: ['./student-enrollments-table.component.scss']
})
export class StudentEnrollmentsTableComponent
  implements OnInit, OnChanges {

  @Input() enrolledStudents: IStudent[];
  @Input() searchValue: string;

  // This is what is show in the table
  filteredStudentData: IStudent[];
  expandSet = new Set<number>();

  constructor(
    private modalService: NzModalService,
    private store: Store<{ scores: any, core: any, instructor: any }>
  ) { }

  ngOnInit(): void {
    this.filterStudents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enrolledStudents) {
      this.filterStudents();
    }
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  public filterStudents(): void {

    this.filteredStudentData = [];

    if (!this.enrolledStudents) {
      return;
    }
    const searchValue = this.searchValue?.toLowerCase();

    let tempResults: IStudent[] = [];

    if (searchValue?.length >= 1) {
      this.enrolledStudents.forEach(s => {
        let wasFound = false;
        if (s.firstName.toLowerCase().startsWith(searchValue)) {
          wasFound = true;
        } else if (s.lastName.toLowerCase().startsWith(searchValue)) {
          wasFound = true;
        } else if (s.identifier.toLowerCase().startsWith(searchValue)) {
          wasFound = true;
        }
        if (wasFound) {
          tempResults.push(s);
        }
      });
    } else {
      tempResults = this.enrolledStudents;
    }
    this.filteredStudentData = tempResults;
  }

  showUnenrollConfirm(student): void {
    this.modalService.warning({
      nzTitle: `Are you sure you want to unenroll ${student.firstName} ${student.lastName}?`,
      nzContent: '<b style="color: red;">Student information will no longer be visible in the course.</b>',
      nzOkText: 'Yes',
      nzOnOk: () => {
        this.store.dispatch(removeStudentEnrollment({
          studentIdentification: student.identifier
        }));
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  sort(sort: { key: string, value: string }): void {

    if (sort.value === null) {
      sort.key = 'displayName';
      sort.value = 'ascend';
    }
    const tempArray = JSON.parse(JSON.stringify(this.filteredStudentData));

    if (sort.key === 'displayName') {
      if (sort.value === 'ascend') {
        tempArray.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
      } else if (sort.value === 'descend') {
        tempArray.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? -1 : 1);
      }

    }
    if (sort.key === 'email') {
      if (sort.value === 'ascend') {
        tempArray.sort((a, b) => a.identifier.toLowerCase() > b.identifier.toLowerCase() ? 1 : -1);
      } else if (sort.value === 'descend') {
        tempArray.sort((a, b) => a.identifier.toLowerCase() > b.identifier.toLowerCase() ? -1 : 1);
      }

    }
    this.filteredStudentData = tempArray;
  }

}
