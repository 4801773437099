import { createAction, props } from '@ngrx/store';

const prefix = '[Core]';

export const getStudentSimInstance = createAction(`${prefix} Get Student Sim Instance`,
  props<{ studentIdentifier: string, productCode: string; }>());

export const updateStudentSimInstanceState = createAction(`${prefix} Update Student Sim Instance State`,
  props<{ state: any, identifier: string; }>());

