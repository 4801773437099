import { Injectable } from '@angular/core';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICourseSettings, IModuleSetting, IPageSetting } from '../../models/simulation/simulation-settings.model';
import { ICourseProductTemplate } from '@stukent/mimic-core';
import { ITemplateVersion } from '../../models/tempate-versions.model';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';
import { IElementSettings } from 'src/app/models/scorable-items/scorable-items-state.model';

@Injectable({
  providedIn: 'root'
})
export class CourseProductTemplateService {
  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  get(): Observable<ICourseProductTemplate> {
    return this.http
      .get<ICourseProductTemplate>(`${this.config.serviceUrls.courseProductTemplate}/${this.course.productCode}/${this.course.courseCode}`);
  }

  getResultsTemplate(): Observable<ICourseProductTemplate> {
    return this.http.get<ICourseProductTemplate>(`${this.config.serviceUrls.resultsTemplate}/${this.course.productCode}/${this.course.courseCode}`);
  }

  setCourseSettings(productCode: string, courseSettings: ICourseSettings): Observable<any> {
    if (Object.keys(courseSettings).length < 1) { return; }
    const url = `${this.config.serviceUrls.simulationManagement}course/settings/${productCode}/${this.course.courseCode}`;
    return this.http.patch(url, courseSettings);
  }

  setModuleSettings(productCode: string, moduleSettings: IModuleSetting[]): Observable<any> {
    const url = `${this.config.serviceUrls.simulationManagement}modules/settings/${productCode}/${this.course.courseCode}`;
    return this.http.patch(url, { modules: moduleSettings });
  }

  setPageSettings(productCode: string, pageSettings: IPageSetting[]): Observable<any> {
    const url = `${this.config.serviceUrls.simulationManagement}pages/settings/${productCode}/${this.course.courseCode}`;
    return this.http.patch(url, { pages: pageSettings });
  }

  setProductTemplateSettings(productCode: string, timezone: string, scoreFormat: string, courseSettings: ICourseSettings, moduleSettings: IModuleSetting[], pageSettings: IPageSetting[], elementSettings: IElementSettings[]): Observable<any> {
    const url = `${this.config.serviceUrls.courseProductTemplateManagement}/course/template/properties/${productCode}/${this.course.courseCode}`;
    return this.http.patch(url, { pages: pageSettings, modules: moduleSettings, courseSettings, timezone, scoreFormat, elements: elementSettings });
  }

  getEndedModules(productCode: string): Observable<any> {
    const url = `${this.config.serviceUrls.courseProductTemplate}/modules/ended/${productCode}/${this.course.courseCode}`;
    return this.http.get(url);
  }

  getVersionInformation(productCode: string): Observable<ITemplateVersion[]> {
    const url = `${this.config.serviceUrls.courseProductTemplateManagement}/${this.course.courseCode}/${this.course.productCode}/newerVersions`;
    return this.http.get<ITemplateVersion[]>(url);
  }

  upgradeTemplate(productCode: string): Observable<string> {
    const url = `${this.config.serviceUrls.courseProductTemplateManagement}/${this.course.courseCode}/${this.course.productCode}/upgrade`;

    return this.http.put(url, {}, { responseType: 'text' });
  }
}
