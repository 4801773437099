import { Injectable } from '@angular/core';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILocation } from '@stukent/mimic-core';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';
@Injectable({
  providedIn: 'root'
})
export class SimManagementService {
  url = '';
  config: IInstructorPortalConfiguration;
  course: ICourseState;

  homeLink = 'https://home.stukent.com';

  constructor(
    public http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  resetSim(studentIdentifier: string, moduleId: string): Observable<boolean> {

    const endpointBaseUrl = this.config.serviceUrls.simulationManagement;

    if (studentIdentifier) {
      if (moduleId) {
        // Reset round
        const endpoint = `${endpointBaseUrl}reset/${this.course.productCode}/${this.course.courseCode}/${studentIdentifier}/${moduleId}`;
        return this.http.put<boolean>(endpoint, {})
          .pipe(
            catchError((err) => this.handleError(err, 'reset module for student'))
          );
      } else {
        // Reset course for a student
        const endpoint = `${endpointBaseUrl}reset/${this.course.productCode}/${this.course.courseCode}/${studentIdentifier}`;
        return this.http.delete<boolean>(endpoint)
          .pipe(
            catchError((err) => this.handleError(err, 'reset sim for student'))
          );
      }
    } else {
      // Reset the entire course
      const endpoint = `${endpointBaseUrl}reset/${this.course.productCode}/${this.course.courseCode}`;
      return this.http.delete<boolean>(endpoint)
        .pipe(
          catchError((err) => this.handleError(err, 'reset sim for course'))
        );
    }
  }

  resetModuleScore(simulationInstanceId: string, moduleId: string): Observable<boolean> {

    const endpointBaseUrl = this.config.serviceUrls.simulationScoring;

    if (simulationInstanceId && moduleId) {
      // Reset round score
      const endpoint = `${endpointBaseUrl}/reset/${this.course.productCode}/${simulationInstanceId}/${moduleId}`;
      return this.http.put<boolean>(endpoint, {})
        .pipe(
          catchError((err) => this.handleError(err, 'reset module score for student'))
        );
    }
  }

  resetScoresByLocationIds(simulationInstanceId: string, locationIds: string[]): Observable<string> {

    const endpointBaseUrl = this.config.serviceUrls.simulationManagement;

    if (simulationInstanceId && locationIds && locationIds.length > 0) {
      // Reset round score
      const endpoint = `${endpointBaseUrl}reset/scores/${this.course.productCode}/${simulationInstanceId}`;
      return this.http.put<string>(endpoint, { locationIds })
        .pipe(
          catchError((err) => this.handleError(err, 'reset scores for student by locationIds'))
        );
    }
  }

  resetQuestionInstance(studentIdentifier: string, questionBankId: string, userInstanceCode: string): Observable<boolean> {

    const endpointBaseUrl = this.config.serviceUrls.questionManagement;

    if (studentIdentifier && questionBankId && userInstanceCode) {
      // Reset round score
      const endpoint = `${endpointBaseUrl}/${questionBankId}/${userInstanceCode}/${studentIdentifier}`;
      return this.http.delete<boolean>(endpoint)
        .pipe(
          catchError((err) => this.handleError(err, 'reset module score for student'))
        );
    }
  }

  resetLocation(studentIdentifier: string, moduleId: string, pageId: string, iterationId: string): Observable<boolean> {

    const endpointBaseUrl = this.config.serviceUrls.simulationManagement;

    if (studentIdentifier && moduleId && pageId) {
      const location: ILocation = { moduleId, pageId, iterationId };
      // Change location - Currently the server side code is expecting a stringified "location" object value
      const endpoint = `${endpointBaseUrl}reset/location/${this.course.productCode}/${this.course.courseCode}/${studentIdentifier}`;
      return this.http.post(endpoint, JSON.stringify(location))
        .pipe(
          catchError((err) => this.handleError(err, 'reset location for student'))
        );
    }
  }

  scoreModuleForStudent(studentIdentifier: string, moduleId: string): Observable<any> {
    const url = `${this.config.serviceUrls.simulationManagement}score/${this.course.productCode}/${this.course.courseCode}/${studentIdentifier}/${moduleId}`;
    return this.http.post(url, {})
      .pipe(
        catchError((err) => this.handleError(err, 'score module for a student'))
      );
  }

  handleError(err: Error | HttpErrorResponse, operation = 'perform an unknown action', isFatal = false): Observable<any> {
    const fatalMessageTemplate = `An unrecoverable error occured while attempting to ${operation}.`;
    const genericMessageTemplate = `An error occurred while we were attempting to ${operation}.`;
    const unauthorizedMessageTemplate = `According to our records, you are not allowed to ${operation}.`;
    let message = genericMessageTemplate;

    if (err && err instanceof HttpErrorResponse) {
      if (isFatal) {
        if (err.status >= 500 && err.status < 600) {
          message = `${fatalMessageTemplate} Please try again later`;
        } else if (err.status === 401) {
          message = `${unauthorizedMessageTemplate} You can <a href="${window.location}">try again</a> (we do make mistakes) or go back to ${this.homeLink} and make sure you are in the right place.`;
        } else {
          message = `${fatalMessageTemplate} Please return to ${this.homeLink} and launch your course again.`;
        }
      }

      if (err.status === 403) {
        message = `We are unable to locate a valid license for you. Try accessing your course through ${this.homeLink}. If the issue persists, please contact support.`;
      }

    } else if (err && err instanceof Error) {
      message = isFatal ? fatalMessageTemplate : genericMessageTemplate;
    }

    return of(err);
  }
}
