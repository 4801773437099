import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { loadLinkedCourse, setLinkedCourseProducts } from './lms-integration.actions';
import { LmsIntegrationService } from 'src/app/services/integration/lms/lmsIntegration.service';
import { ILinkedCourseData } from 'src/app/models/lms-integration.models';

@Injectable()
export class LmsIntegrationEffects {

  constructor(
    private actions$: Actions,
    private lmsIntegrationService: LmsIntegrationService
  ) {
  }

  @Effect()
  loadLinkedCourse$ = this.actions$.pipe(
    ofType(loadLinkedCourse),
    switchMap(() =>
      this.lmsIntegrationService.getCourse()
        .pipe(
          map((course: ILinkedCourseData) => setLinkedCourseProducts({ linkedProducts: course.linkedProducts })),
          catchError((err) => {
            console.error('ERROR', err);
            return EMPTY;
          })
        )
    )
  );
}
