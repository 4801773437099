import { createReducer, on, Action } from '@ngrx/store';
import { IScorableItemsState } from '../../models/scorable-items/scorable-items-state.model';
import { setQuestionBankSettings, setScorableItemConfiguration } from './scorable-items.actions';

export const initialState: IScorableItemsState = {
  scorableItems: {
    questionBank: []
  },
  scorableItemsConfiguration: []
};

const reducer = createReducer(initialState,
  on(setQuestionBankSettings, (state, { questionBankSettings }) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.scorableItems.questionBank = questionBankSettings;

    return newState;
  }),
  on(setScorableItemConfiguration, (state, { config }) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.scorableItemsConfiguration = config;

    return newState;
  })
);

export function scorableItemsReducer(settings: IScorableItemsState, action: Action) {
  return reducer(settings, action);
}
