import { createSelector } from '@ngrx/store';
import { IInstructorPortalState } from './root';

export const instrcutorFeature = state => state.instructorPortal as IInstructorPortalState;

export const selectInstructorPortalConfiguration = createSelector(
    instrcutorFeature,
    instructor => instructor.instructorPortalConfig?.config
);

export const selectCurrentCourse = createSelector(
    instrcutorFeature,
    instructor => instructor.course
);

export const selectCurrentProductCode = createSelector(
    instrcutorFeature,
    instructor => instructor.course?.productCode
);

export const selectCurrentCourseEnrollments = createSelector(
    instrcutorFeature,
    instructor => instructor.enrollment
);

export const selectCurrentCourseProductTemplate = createSelector(
    instrcutorFeature,
    instructor => instructor.template
);

export const selectCurrentCourseScorableItems = createSelector(
    instrcutorFeature,
    instructor => instructor.scorableItems
);
