import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IInstructorPortalConfiguration } from '../models/app-config.model';
import { environment } from 'src/environments/environment';
import { currentUser } from '@stukent/user';
import { retry } from 'rxjs/operators';
import { selectInstructorPortalConfiguration } from '../reducers/selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  token: string;
  config: IInstructorPortalConfiguration;

  constructor(
    private store: Store
  ) {
    this.store.select(currentUser).subscribe(x => this.token = x.jwt);
    this.store.select(selectInstructorPortalConfiguration).subscribe(x => this.config = x);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (/https:\/\/api.stukent.com\/config\/instructor/i.test(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': environment.configKey
        }
      });
      return next.handle(request).pipe(retry(2));
    }

    // use the ranking key for metrics
    // TODO: Remove the ranking condition here when the metrics refactor is completed - ER
    if (this.token && (request.url.indexOf('/ranking/') > 0 || request.url.indexOf('/metrics/') > 0)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.rankingSubscriptionKey
        }
      });
      return next.handle(request).pipe(retry(2));
    }

    if (this.token && request.url.indexOf('/resources/') > 0) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.instructorResourcesSubscriptionKey
        }
      });
      return next.handle(request).pipe(retry(2));
    }

    if (this.token && request.url.indexOf('/supplemental/') > 0) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.simulationSubscriptionKey
        }
      });
      return next.handle(request).pipe(retry(2));
    }

    // identity.stukent.com/lms/v1 is the URL we are targeting here
    if (this.token && request.url.indexOf(this.config.serviceUrls.identityLmsUrl) >= 0) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.identityLmsSubscriptionKey
        }
      });
      return next.handle(request).pipe(retry(2));
    }

    if (this.token &&
      (request.url.indexOf(this.config.serviceUrls.mediaService) >= 0
        || request.url.indexOf(this.config.serviceUrls.mediaServiceUpload) >= 0)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.mediaServicesSubscriptionKey
        }
      });
      return next.handle(request).pipe(retry(2));
    }

    // use the Ecosystem key
    if (this.token && request.url.indexOf('/ecosystem/') > 0) {
      // Not sure why this requires a full replace of the headers other than there was already a Subscription Key header in the request
      const newHeaders = new HttpHeaders().set('Ocp-Apim-Subscription-Key', this.config.ecosystemSubscriptionKey);
      const finalHeaders = newHeaders.set('Authorization', `Bearer ${this.token}`);

      request = request.clone({
        headers: finalHeaders
      });

      return next.handle(request).pipe(retry(1));

    }

    // standard jwt header but no retries
    if (this.token && request.url.indexOf('/questions/v2') > 0) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.subscriptionKey
        }
      });

      return next.handle(request);
    }

    // standard jwt header but no retries
    if (this.token && request.url.indexOf('/instructions/') > 0) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.instructionsSubscriptionKey
        }
      });

      return next.handle(request);
    }



    // add authorization header with jwt token if available
    if (this.token && request.url.indexOf('.js') === -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Ocp-Apim-Subscription-Key': this.config.subscriptionKey
        }
      });

      return next.handle(request).pipe(retry(3));
    }


    return next.handle(request).pipe(retry(1));
  }
}
