import { createAction, props } from '@ngrx/store';
import { IScore } from '@stukent/mimic-core';
import { ICourseProductScoresSummary } from '../../models/simulation/scores/student-work-summary.model';

const prefix = '[Scores]';

export const loadStudentSummary = createAction(`${prefix} Load Student Summary`);

export const setStudentSummary = createAction(`${prefix} Set Student Summary`, props<{ summary: ICourseProductScoresSummary; }>());

export const loadStudentModuleScores = createAction(
  `${prefix} Load Student Module Score`,
  props<{ studentIdentifier: string, simulationInstanceId: string, moduleId: string, iterationsByModule: any; }>()
);

export const loadStudentsScores = createAction(
  `${prefix} Load Students Scores`,
  props<{ simulationInstanceId: string, moduleId: string, iterationsByModule: any; }>()
);

export const setStudentModuleScores = createAction(
  `${prefix} Set Student Module Score`,
  props<{ scores: IScore[], moduleId: string, iterationsByModule: any; }>()
);

export const setAllScores = createAction(
  `${prefix} Set Students Scores`,
  props<{ allScores: any[]; }>()
);

export const clearStudentModuleScore = createAction(`${prefix} Clear Student Module Score`);

