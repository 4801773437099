import { ActionReducerMap, ActionReducer } from '@ngrx/store';
import { enrollmentReducer, IEnrollmentState } from './enrollment/enrollment.reducer';
import { courseReducer, ICourseState } from './course/course.reducer';
import { scoresReducer } from './scores/scores.reducer';
import { ICourseProductTemplateState, templateReducer } from './template/template.reducer';
import { IInstructorResourceState } from '../models/resources/instructor-resource.model';
import { instructorResourceReducer } from './instructor-resource/instructor-resource.reducer';
import { scorableItemsReducer } from './scorable-items/scorable-items.reducer';
import { IScorableItemsState } from '../models/scorable-items/scorable-items-state.model';
import { IInstructorPortalConfigState, instructorPortalConfigReducer } from './config/config.reducer';
import { ILmsIntegrationState, lmsIntegrationReducer } from './lms-integration/lms-integration.reducer';

export interface IInstructorPortalState {
  instructorPortalConfig: IInstructorPortalConfigState;
  enrollment: IEnrollmentState;
  course: ICourseState;
  template: ICourseProductTemplateState;
  instructorResources: IInstructorResourceState;
  scorableItems: IScorableItemsState;
  lmsIntegration: ILmsIntegrationState;
}


export const reducers: ActionReducerMap<IInstructorPortalState> = {
  instructorPortalConfig: instructorPortalConfigReducer,
  enrollment: enrollmentReducer,
  course: courseReducer,
  template: templateReducer,
  instructorResources: instructorResourceReducer,
  scorableItems: scorableItemsReducer,
  lmsIntegration: lmsIntegrationReducer
};

export const scoringReducer: ActionReducer<any> = scoresReducer;

// not sure if this is the best place, but a lot of selectors use state.instructor
// so I added it in the root to be shared to other selectors (ER)
export const baseInstructorPortalSelector = state => state.instructorPortal as any;
