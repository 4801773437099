import { createAction, props } from '@ngrx/store';
import { IScorableItem, IScorableItemConfiguration } from '../../models/scorable-items/scorable-item.model';
import { IQuestionBankSettings } from '../../models/scorable-items/scorable-items-state.model';
import { IDueDateTimers } from '../../models/simulation/simulation-settings.model';

const prefix = '[ScorableItems]';

// Scorable Items
export const loadQuestionBankSettings = createAction(
  `${prefix} Load Question Bank Settings`
);

export const setQuestionBankSettings = createAction(
  `${prefix} Set Question Bank Settings`,
  props<{ questionBankSettings: IQuestionBankSettings[] }>()
);

export const saveQuestionBankSettings = createAction(
  `${prefix} Save Question Bank Settings`,
  props<{ questionBankSettings: IQuestionBankSettings[] }>()
);

export const loadProductScoringConfiguration = createAction(
  `${prefix} Load Product Scoring Configuration`
);

export const setScorableItemConfiguration = createAction(
  `${prefix} Set Product Scoring Configuration`,
  props<{ config: IScorableItemConfiguration[] }>()
);

export const resetScorableItem = createAction(
  `${prefix} Reset Scorable Item`,
  props<{ scorableItem: IScorableItem }>()
);

export const startStopDueDateTimers = createAction(
  `${prefix} Start or Stop Due Date Timers`,
  props<{ timers: IDueDateTimers }>()
);
