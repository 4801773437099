import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ICourseProductTemplate } from '@stukent/mimic-core';
import { NzInputNumberComponent } from 'ng-zorro-antd';
import { ICourseSettings, IPageSetting } from '../../../models/simulation/simulation-settings.model';
import { IQuestionBankSettingProperties, IQuestionBankSettings } from '../../../models/scorable-items/scorable-items-state.model';

interface IQuestionBankSettingsRow extends IQuestionBankSettingProperties {
  elementId: string;
  pageId: string;
  pageDisplayName: string;
  moduleId: string;
  moduleDisplayName: string;
  title: string;
  questionBankId: string;
  limitTimeEnabled?: boolean;
}

@Component({
  selector: 'app-scorable-items-settings',
  templateUrl: './scorable-items-settings.component.html',
  styleUrls: ['./scorable-items-settings.component.scss']
})
export class ScorableItemsSettingsComponent implements OnInit, OnChanges {

  @Input() questionBankSettings: IQuestionBankSettings[];
  @Input() pageSettings: IPageSetting[];
  @Input() courseProductTemplate: ICourseProductTemplate;
  @Input() isLinkedToLms: boolean;

  @Output() questionBankSettingsChanged = new EventEmitter<IQuestionBankSettings[]>();
  @Output() pageSettingsChanged = new EventEmitter<IPageSetting>();
  @Output() quizEnabledChanged = new EventEmitter<boolean>();

  @ViewChildren(NzInputNumberComponent) limitTimeInputs: QueryList<NzInputNumberComponent>;

  lastSelectedDate: Date;
  quizzesEnabled: boolean;
  courseSettings: ICourseSettings;

  // Scorable items are currently only question banks, but in the future will be other things as well
  rows: IQuestionBankSettingsRow[] = [];

  ngOnInit(): void {
    this.setEnableQuizzesFromTemplate();
    this.populateRowsFromTemplates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.rows.length && changes?.isLinkedToLms?.currentValue) {
      // Clear dates
      this.rows = this.rows.map(row => {
        return {
          ...row,
          dueDate: null
        };
      });
    }
  }

  disabledDueDates = (current: Date): boolean => {
    // Can not select days before today and today
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    return current < currentDate;
  }

  disabledDueHours = (): number[] => {
    const currentDate = new Date();
    const notAllowedHours = [];
    const index = currentDate.getHours();

    if (currentDate?.getDate() === this.lastSelectedDate?.getDate()) {
      for (let i = 0; i < index; i++) {
        notAllowedHours.push(i);
      }
    }

    return notAllowedHours;
  }

  disabledDueMinutes = (hour: number, minute: number): number[] => {
    const currentDate = new Date();
    const notAllowedMinutes = [];
    const index = currentDate.getMinutes();
    const currentHour = currentDate.getHours();

    if (currentHour === hour && currentDate?.getDate() === this.lastSelectedDate?.getDate()) {
      for (let i = 0; i < index; i++) {
        notAllowedMinutes.push(i);
      }
    }

    return notAllowedMinutes;
  }

  changedDueDate(newDate: Date, row: IQuestionBankSettingsRow) {
    newDate = (newDate < new Date() && newDate != null) ? new Date() : newDate;
    row.dueDate = newDate;

    if (newDate) {
      newDate.setSeconds(0, 0);
    }
    this.lastSelectedDate = newDate;
    if (newDate === null) {
      this.emitDueDateSettings(false, row);
    }
  }

  emitDueDateSettings(isOpen: boolean, row: IQuestionBankSettingsRow) {
    // Only emit changes on close, rather than every time the model changes
    if (!isOpen) {
      this.updateSetting(row.questionBankId, row.moduleId, 'dueDate', row.dueDate);
    }
  }

  changedEnabled(enabled: boolean, row: IQuestionBankSettingsRow) {

    this.updatePageSetting(row.pageId, row.moduleId, 'enabled', enabled);

    this.updateSetting(row.questionBankId, row.moduleId, 'enabled', enabled);

    if (!enabled) {
      row.timeLimit = null;
      row.dueDate = null;
      row.limitTimeEnabled = false;
      this.updateSetting(row.questionBankId, row.moduleId, 'timeLimit', 0);
      this.updateSetting(row.questionBankId, row.moduleId, 'dueDate', null);
    }
  }

  onEnableQuizzesSwitch(quizzesEnabledToggle: boolean) {
    this.quizEnabledChanged.emit(quizzesEnabledToggle);
  }

  changedTimeLimit(limit: number, row: IQuestionBankSettingsRow) {
    if (limit <= 0) {
      if (limit) {
        row.limitTimeEnabled = false;
      }
      limit = undefined;
    }
    row.timeLimit = limit;
    limit = (row.limitTimeEnabled) ? limit : undefined;
    this.updateSetting(row.questionBankId, row.moduleId, 'timeLimit', limit);
  }

  private updatePageSetting(pageId: string, moduleId: string, settingName: string, enabled: any) {
    let page = this.pageSettings?.find(s => s.id === pageId);

    if (page) {
      page = JSON.parse(JSON.stringify(page));
      page.settings[settingName] = enabled;
    }
    else {
      page = this.createNewPageSettings(pageId, moduleId, enabled);
    }

    this.pageSettingsChanged.emit(page);
  }

  private createNewPageSettings(pageId: string, moduleId: string, enabled: boolean): IPageSetting {
    // setup default IPageSetting, we are including the moduleId for when we need to update settings
    // we don't have to loop for each module, we can find it by moduleId > pageId
    return {
      moduleId,
      id: pageId,
      settings: {
        enabled
      }
    };
  }


  private updateSetting(questionBankId: string, moduleId: string, settingName: string, value: any) {
    let questionBank = this.questionBankSettings.find(s => s.questionBankId === questionBankId);

    if (questionBank) {
      questionBank = JSON.parse(JSON.stringify(questionBank));
    } else {
      questionBank = this.createNewSettings(questionBankId, moduleId);
    }

    questionBank.settings[settingName] = value;
    const newSettings = this.questionBankSettings.filter(s => s.questionBankId !== questionBankId);
    this.questionBankSettings = [...newSettings, questionBank];
    this.questionBankSettingsChanged.emit(this.questionBankSettings);


  }

  onTimeLimitCheck(row) {
    if (!row.timeLimit && row.limitTimeEnabled) {
      row.limitTimeEnabled = false;
    }
  }

  onItemChecked(checked, row) {
    if (checked) {
      row.limitTimeEnabled = true;
      setTimeout(() => {
        const element = this.limitTimeInputs.toArray().find(x => x.nzId === row.elementId);
        element.focus();
      }, 0);
    } else {
      row.timeLimit = undefined;
      row.limitTimeEnabled = false;
      this.updateSetting(row.questionBankId, row.moduleId, 'timeLimit', undefined);
    }
  }

  private createNewSettings(questionBankId: string, moduleId: string): IQuestionBankSettings {
    return {
      moduleId,
      questionBankId,
      settings: {
        timeLimit: 0,
        enabled: true
      }
    };
  }

  // Sets the Enable Quizzes toggle to the propert in CPT
  private setEnableQuizzesFromTemplate() {
    const originaCourseSettings = JSON.parse(JSON.stringify(this.courseProductTemplate.properties)) as ICourseSettings;

    // If quizzesEnabled exists, set to it's value, otherwise default to true
    this.quizzesEnabled = originaCourseSettings.enableQuizzes !== undefined ? originaCourseSettings.enableQuizzes : true; // set default value
  }

  private populateRowsFromTemplates() {
    if (!this.courseProductTemplate || !this.questionBankSettings) { return; }

    this.rows = [];
    this.courseProductTemplate.modules.forEach(m => {
      m.pages.forEach(p => {
        const allElements = p.elements || [];
        const questionBankTemplates = allElements.filter(el => el.type === 'question-bank');
        questionBankTemplates.forEach(el => {
          const questionBankConfiguration = this.questionBankSettings.find(s => s.questionBankId === el.
            config?.questionBankId);
          const pageSettings = this.pageSettings.find(x => x.id === el.
            config?.questionBankId);

          this.rows.push({
            elementId: el.id,
            pageId: p.id,
            pageDisplayName: p.displayName,
            moduleId: m.id,
            moduleDisplayName: m.displayName,
            title: el.config?.title,
            questionBankId: el.config?.questionBankId,
            dueDate: !this.isLinkedToLms ? questionBankConfiguration?.settings?.dueDate : null,
            timeLimit: questionBankConfiguration?.settings?.timeLimit > 0 ? questionBankConfiguration?.settings?.timeLimit : null,
            enabled: pageSettings.settings?.enabled ?? true,
            limitTimeEnabled: !!questionBankConfiguration?.settings?.timeLimit ?? false
          });
        });
      });
    });
  }


}
