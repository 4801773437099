import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { IScorableItem, IScorableItemConfiguration } from '../../../models/scorable-items/scorable-item.model';
import { IQuestionBankSettings, IScorableItemsState } from '../../../models/scorable-items/scorable-items-state.model';
import { ICourseScores, IStudentScoreSummary } from '../../../models/simulation/scores/simulation-results';
import { updateStudentSimInstanceState } from '../../../reducers/core/core.actions';
import { loadStudentsScores } from '../../../reducers/scores/scores.actions';
import { SimInstanceService } from '../../../services/simInstance/simInstance.service';
import { ExportService } from '../../../services/scores/export.service';
import { DecimalPipe } from '@angular/common';
import { loadProductScoringConfiguration, loadQuestionBankSettings, resetScorableItem } from 'src/app/reducers/scorable-items/scorable-items.actions';
import { IScore, ISimulationInstance } from '@stukent/mimic-core';
import { selectStudentSimInstance } from 'src/app/reducers/simulation/simulation.selectors';
import { v4 as uuid } from 'uuid';

interface IScorableItemRow extends IScorableItem {
  rowId: string;
  itemName: string;
}
@Component({
  selector: 'app-scorable-items-scores',
  templateUrl: './scorable-items-scores.component.html',
  styleUrls: ['./scorable-items-scores.component.scss']
})
export class ScorableItemsScoresComponent implements OnInit, OnDestroy, OnChanges {
  @Input() studentSummary: IStudentScoreSummary;
  @Input() showStudentScorableItemsModal: boolean;
  @Input() scorableItems: IScorableItemsState;
  @Input() studentScores: IStudentScoreSummary[];
  @Input() allScores: IScore<any>[];
  @Input() scoreType: string;
  @Input() includeUnEnrolledResults: boolean;

  private subscriptions: Subscription[] = [];
  private questionBankConfigs: IQuestionBankSettings[];
  private studentInstance: ISimulationInstance;

  public scorableItemsRows: IScorableItemRow[];
  public allowExtraTime = false;
  public extraTime;
  public extraTimeType = 'minutes';
  public resettingRows: string[] = [];
  public reloading = false;
  public exportScoresDisabled = false;
  public enableSave = false;
  public loadingSimInstance = true;

  constructor(
    private store: Store,
    private modalService: NzModalService,
    private exportService: ExportService,
    private simInstanceService: SimInstanceService,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    this.exportScoresDisabled = (this.allScores?.length === 0);

    this.subscriptions.push(this.store.select(selectStudentSimInstance).subscribe(studentInstance => {
      if (studentInstance?.studentIdentifier === this.studentSummary?.identifier) {
        this.loadingSimInstance = false;
      }
      this.studentInstance = studentInstance;
      this.loadExtraTimeData();
    }));

    // Keep scores and settings up to date when the modal is opened
    this.loadStudentResults();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.studentSummary) { return; }

    this.exportScoresDisabled = (this.allScores?.length === 0);

    if (this.scorableItems) {
      this.questionBankConfigs = this.scorableItems.scorableItems.questionBank || [];
    }

    if (this.questionBankConfigs && this.scorableItems?.scorableItemsConfiguration && this.allScores) {
      this.buildQuestionBankScorableItems();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  handleResetClick(row: IScorableItemRow) {
    this.modalService.confirm({
      nzTitle: `Are you sure you want to reset ${row.itemName}?`,
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnOk: () => {
        this.resettingRows.push(row.rowId);
        this.store.dispatch(resetScorableItem({
          scorableItem: row
        }));
        return true;
      }
    });
  }

  private buildQuestionBankScorableItems() {

    const scorableItems = this.scorableItems.scorableItemsConfiguration.filter(c => c.scorableItemType === 'QuestionBank');
    this.scorableItemsRows = scorableItems.map((item: IScorableItemConfiguration) => {
      let row: IScorableItemRow = {
        rowId: item.moduleId + '.' + item.elementId,
        scorableItemConfiguration: item,
        studentIdentifier: this.studentSummary.identifier,
        itemName: item.scorableItemName,
        displayScore: '-- / --',
        dateDue: null,
        dateSubmitted: null,
        persistedScore: null,
        hasScore: false,
        hasConfig: false,
        config: null,
      };

      row = this.setQuestionBankConfigOnRow(row);
      row = this.setScoresOnRow(row);

      return row;
    });

    this.reloading = false;
  }

  private setQuestionBankConfigOnRow(row: IScorableItemRow): IScorableItemRow {
    row.config = null;
    row.dateDue = null;
    row.hasConfig = false;
    if (row.scorableItemConfiguration.scorableItemType !== 'QuestionBank') { return row; }

    const questionBankId = row.scorableItemConfiguration?.configuration?.questionBankId;

    const qbConfig = this.questionBankConfigs.find(c => c.questionBankId === questionBankId);
    if (!qbConfig) { return row; }

    row.config = qbConfig;
    row.dateDue = qbConfig.settings.dueDate;
    row.hasConfig = true;

    return row;
  }

  private setScoresOnRow(row: IScorableItemRow): IScorableItemRow {

    row.hasScore = false;
    row.dateSubmitted = null;
    row.displayScore = '-- / --';
    row.persistedScore = null;

    if (!this.studentScores) { return row; }

    const scorerId = row.scorableItemConfiguration.scorerId;

    const score = this.allScores.filter(as => as.context.studentIdentifier === this.studentSummary.identifier).find(s => s.scorerId === scorerId);
    if (!score) {
      // If there is no score, ensure that the row is no longer in 'reset' mode (because its score has been cleared)
      this.resettingRows = this.resettingRows.filter(rowId => rowId !== row.rowId);
      return row;
    }

    // If there is a score for a row being reset, then we need to wait until its score is cleared
    if (this.resettingRows.includes(row.rowId)) {
      return row;
    }

    row.hasScore = true;
    if (score.createdDate) {
      row.dateSubmitted = new Date(score.createdDate);
    }

    if (!score.possiblePoints || score.possiblePoints === 0) {
      row.displayScore = `${score.scorePercent * 100}%`;
      return row;
    }

    const points = score.scorePercent * score.possiblePoints;
    row.displayScore = `${this.decimalPipe.transform(points, '1.0-0')} / ${score.possiblePoints}`;
    row.persistedScore = score;
    return row;
  }

  public timeLimitFormat(formatNumber) {
    // If the user put a decimal number, we want it to change it into an integer
    if (formatNumber) {
      return Math.floor(formatNumber);
    }
    return formatNumber;
  }

  private loadExtraTimeData(): void {
    // check if we have extra time saved in state, also we need to check if this is different from 0
    if ((this.studentInstance?.state?.extraTime && this.studentInstance?.state?.extraTime > 0) ||
      (this.studentInstance?.state?.extraPercent && this.studentInstance?.state?.extraPercent > 0)) {

      // Turn on checkbox
      this.allowExtraTime = true;

      // Check what type of extra time has and how much
      if (this.studentInstance?.state?.extraPercent) {
        this.extraTimeType = 'percentage';
        this.extraTime = this.studentInstance?.state?.extraPercent * 100;
      } else {
        this.extraTimeType = 'minutes';
        this.extraTime = this.studentInstance?.state?.extraTime;
      }
    }
  }

  public enableSaveFromChange(): void {
    if (this.allowExtraTime && this.extraTime > 0) {
      this.enableSave = true;
    }
  }

  public onSaveButtonClick(): void {
    const newState = {
      state: {
        ...this.studentInstance?.state ?? {},
        extraTime: this.extraTime,
        extraPercent: this.extraTime / 100
      }
    };

    this.enableSave = false;
    // Check if we have enabled the extra time, and which type of extra time
    if (this.allowExtraTime && this.extraTimeType === 'minutes') {
      // Time is setted in minutes, we delete percent property from state
      delete newState.state.extraPercent;
    }
    else if (this.allowExtraTime && this.extraTimeType === 'percentage') {
      // Time is setted in percent, we delete time property from state
      delete newState.state.extraTime;
    } else {
      // Delete both from element state if there is no extra time
      delete newState.state.extraTime;
      delete newState.state.extraPercent;
    }

    this.store.dispatch(updateStudentSimInstanceState({ state: newState, identifier: this.studentSummary.identifier }));
  }

  loadStudentResults(): void {
    this.reloading = true;

    // Get new scores
    this.allScores = [];
    this.store.dispatch(
      loadStudentsScores({
        simulationInstanceId: this.studentInstance.id,
        moduleId: null,
        iterationsByModule: null
      })
    );

    // Get new scorable item configurations/settings
    this.questionBankConfigs = null;
    this.scorableItems = null;
    this.store.dispatch(loadProductScoringConfiguration());
    this.store.dispatch(loadQuestionBankSettings());
  }

  exportStudentData(): void {
    if (this.scorableItems) {
      const courseScores = {
        courseProductResults: this.studentScores,
        scorableItems: this.scorableItems,
        allScores: this.allScores,
        scoreType: this.scoreType,
        unEnrolledStudentsAreShown: this.includeUnEnrolledResults
      } as ICourseScores;
      this.exportService.exportAllScorableItems(courseScores, this.studentSummary);
    }
  }

  trackByRowId(index: number, row: IScorableItemRow) {
    return row.rowId;
  }
}


