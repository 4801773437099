import { createReducer, on, Action } from '@ngrx/store';
import { ITemplateVersion } from '../../models/tempate-versions.model';
import { loadVersionInformationFailed, setVersionInformation, upgradeTemplate, upgradeTemplateFailed, upgradeTemplateSucceeded } from './templateVersions.actions';

export interface ITemplateVersionsState {
  versions: ITemplateVersion[];
  areLoaded: boolean;
  isFaulted: boolean;
  errorMessage?: string;
}

export const initialState: ITemplateVersionsState = {
  versions: [],
  areLoaded: false,
  isFaulted: false
};

const reducer = createReducer(initialState,
  on(setVersionInformation, (state, { versions }) => {
    return { ...state, versions, areLoaded: true };
  }),
  on(loadVersionInformationFailed, (state, { errorMessage }) => {
    return { ...state, errorMessage, isFaulted: true, areLoaded: true };
  }),
  on(upgradeTemplate, (state, { productCode }) => {
    return { ...state, versions: [], isFaulted: false, areLoaded: true };
  }),
  on(upgradeTemplateSucceeded, (state) => {
    return { ...state, versions: [], isFaulted: false, areLoaded: true };
  }),
  on(upgradeTemplateFailed, (state, { errorMessage, versions }) => {
    return { ...state, versions, errorMessage, isFaulted: true, areLoaded: true };
  })
);

export function templateVersionsReducer(version: ITemplateVersionsState, action: Action) {
  return reducer(version, action);
}

