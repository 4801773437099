import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { IQuestionBankSettings } from '../../models/scorable-items/scorable-items-state.model';
import { IDueDateTimers } from '../../models/simulation/simulation-settings.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

interface IAPIResult {
  id: string;
  courseCode: string;
  productCode: string;
  questionBankConfigurations: IQuestionBankSettings[];
  createdDate: string;
  updatedDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class QuestionBankScorableItemsService {

  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);

  }

  private getConfigBaseUrl(): string {
    const { serviceUrls } = this.config;
    return serviceUrls?.questionManagement;
  }

  private getBaseUrl(): string {
    const { serviceUrls } = this.config;
    return serviceUrls?.questionBankConfigurationUrl;
  }

  getQuestionBankConfiguration(): Observable<IQuestionBankSettings[]> {
    const { courseCode, productCode } = this.course;
    const baseUrl = this.getBaseUrl();

    if (!courseCode || !productCode || !baseUrl) {
      return EMPTY;
    }

    return this.http.get(`${baseUrl}/${courseCode}/${productCode}`).pipe(
      catchError(err => {
        if (err.status === 404) {
          return EMPTY;
        }
        return EMPTY;
      }),
      map((qb: IAPIResult) => qb.questionBankConfigurations)
    );
  }

  saveQuestionBankConfiguration(settings: IQuestionBankSettings[]): Observable<any> {
    const { courseCode, productCode } = this.course;
    const baseUrl = this.getConfigBaseUrl();
    if (!courseCode || !productCode || !baseUrl) {
      return EMPTY;
    }

    return this.http.post(`${baseUrl}/${courseCode}/${productCode}`, {
      questionBankConfigurations: settings
    });
  }

  startStopDueDateTimers(body: IDueDateTimers) {
    const { courseCode, productCode } = this.course;
    const baseUrl = this.getBaseUrl();
    return this.http.post(`${baseUrl}StartStopDueDateTimers/${courseCode}/${productCode}`, body);
  }
}
