import { Injectable } from '@angular/core';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { IInstructorResourceState, IResourceState } from '../../models/resources/instructor-resource.model';
import { catchError } from 'rxjs/operators';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class InstructorResourcesService {

  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  /**
   * Returns resources with product
   *
   * @returns IInstructorResourceState from api
   */
  getInstructorResources(): Observable<IInstructorResourceState> {
    return this.http
      .get<IInstructorResourceState>(`${this.config.serviceUrls.instructorResources}${this.course.productCode}`)
      .pipe(catchError(this.handleError));
  }


  /**
   * Returns resources with specific icon for type
   *
   * @param resources - IResource collection
   * @returns IResource collection[]
   */
  getResourcesWithIcon(resources: IResourceState[]): IResourceState[] {
    if (!resources) {
      return;
    }

    let resourceCopy: IResourceState[] = JSON.parse(JSON.stringify(resources));
    resourceCopy = resourceCopy.map(r => {
      r.icon = this.getIconByFileType(r.type);
      return r;
    });

    return resourceCopy;
  }

  /**
   * Returns a downloaded file from url as blob type
   *
   * @param path - path of the file
   * @returns Observable Blob file
   */
  downloadFile(path: string): Observable<Blob> {
    const url = `${this.config.serviceUrls.instructorResources}resource/${path}`;
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * return the icon string from type
   *
   * @param type - type of the file
   * @returns icon string
   */
  private getIconByFileType(type: string) {
    switch (type) {
      case 'pdf':
        return 'pdf-box';
      case 'xlsx':
      case 'excel':
      case 'csv':
        return 'microsoft-excel';
      case 'doc':
      case 'docx':
        return 'file-document-outline';
      case 'ppt':
      case 'pptx':
        return 'file-powerpoint';
      default:
        return 'file-table-box';
    }
  }


  /**
   * Private method for handling errors
   * @param error Observable error
   * @returns Observable<never>
   */
  private handleError(error: Error): Observable<never> {
    return throwError(`Failed fetching Instructor Resources: ${error}`);
  }

}


