import { createAction, props } from '@ngrx/store';
import { IStudent } from '../../models/student.model';

const prefix = '[Enrollments]';
export const loadEnrollments = createAction(`${prefix} Load`);
export const setEnrollments = createAction(`${prefix} Set`, props<{ students: IStudent[] }>());
export const setEnrollmentsFailed = createAction(`${prefix} Failed to Set`, props<{ errorMessage: string }>());
export const removeStudentEnrollment = createAction(`${prefix} remove enrollment`, props<{ studentIdentification: string }>());
export const removeStudentEnrollmentSuccess = createAction(`${prefix} Successfully removed enrollment`, props<{ studentIdentification: string }>());
export const removeStudentEnrollmentFailed = createAction(`${prefix} Failed to remove enrollment`, props<{ errorMessage: string }>());

// LMS enrollment/rostering
export const getLmsEnrollments = createAction(`${prefix} Get LMS Enrollments`);
export const getLmsEnrollmentsSucceeded = createAction(`${prefix} Got LMS Enrollments`, props<{ students: IStudent[]; }>());
export const getLmsEnrollmentsFailed = createAction(`${prefix} Failed to Get LMS Enrollments`, props<{ errorMessage: string }>());
