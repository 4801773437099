import { createAction, props } from '@ngrx/store';
import { ICourse, IMyCourse } from '../../models/course.model';

const prefix = '[Course]';
export const setCourseCode = createAction(`${prefix} Set Course Code`, props<{ courseCode: string }>());
export const loadCourse = createAction(`${prefix} Load`);
export const loadMyCourses = createAction(`${prefix} Load My Courses`);
export const setMyCourses = createAction(`${prefix} Set My Courses`, props<{ courses: IMyCourse[] }>());
export const setCourse = createAction(`${prefix} Set`, props<{ course: ICourse }>());
export const setCourseFailed = createAction(`${prefix} Failed to Load`, props<{ errorMessage: string }>());

export const setProductCode = createAction(`${prefix} Set Product Code`, props<{ productCode: string }>());
