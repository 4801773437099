import { createReducer, on, Action } from '@ngrx/store';
import { setCourse, setCourseCode, setCourseFailed, setMyCourses, setProductCode } from './course.actions';
import { ICourse, IMyCourse } from '../../models/course.model';

export interface ICourseState {
  isLoading: boolean;
  isFaulted: boolean;
  errorMessage: string;
  courseCode: string;
  productCode: string;
  course: ICourse;
  myCourses: IMyCourse[];
}

export const initialState: ICourseState = {
  isLoading: true,
  isFaulted: false,
  errorMessage: '',
  courseCode: '',
  productCode: '',
  course: {
    courseCode: '',
    products: [],
    title: ''
  },
  myCourses: []
};

const reducer = createReducer(initialState,
  on(setCourse, (state, { course }) => {
    if (course.courseCode) {
      return { ...state, isLoading: false, course };
    } else {
      return state;
    }
  }),
  on(setCourseFailed, (state, { errorMessage }) => {
    return {... state, errorMessage, isFaulted: true, isLoading: false};
  }),
  on(setCourseCode, (state, { courseCode }) => ({ ...state, courseCode })),
  on(setMyCourses, (state, { courses }) => ({ ...state, myCourses: courses })),
  on(setProductCode, (state, { productCode }) => ({ ...state, productCode }))
);

export function courseReducer(user: ICourseState, action: Action) {
  return reducer(user, action);
}
