// PETITION TO RENAME THIS COMPONENT TO THE STUKENT SCORES COMPONET - CAM

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { FeatureArea, FeatureService } from '@stukent/feature-toggle';
import { currentUser, IUser } from '@stukent/user';
import { ScoresService } from '../../services/scores/scores.service';
import { SimulationScoresTableComponent } from './simulation-scores/simulation-scores-table/simulation-scores-table.component';
import { ICourseProductInformation, ICourseScores, IStudentScoreSummary } from '../../models/simulation/scores/simulation-results';
import { ICourseProductTemplate, IScore } from '@stukent/mimic-core';
import { IScorableItemsState } from '../../models/scorable-items/scorable-items-state.model';
import { ExportService } from '../../services/scores/export.service';
import { loadProductScoringConfiguration } from '../../reducers/scorable-items/scorable-items.actions';
import { selectCurrentCourse } from 'src/app/reducers/selectors';

@Component({
  selector: 'app-student-scores',
  templateUrl: './student-scores.component.html',
  styleUrls: ['./student-scores.component.scss']
})
export class StudentScoresComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() courseProductInformation: ICourseProductInformation;
  @Input() courseProductResults: IStudentScoreSummary[];
  @Input() courseProductTemplate: ICourseProductTemplate;
  @Input() scorableItems: IScorableItemsState;
  @Input() allScores: IScore<any>[];


  @Output() updateResults = new EventEmitter();

  // Get view children to send stuff to and from
  @ViewChild('search') private search: ElementRef;
  @ViewChild(SimulationScoresTableComponent) private scoresTable: SimulationScoresTableComponent;

  private subscriptions: Subscription[] = [];

  scoreType = 'points';
  searchValue = '';

  // Used to control the Sim Modules Legend
  showLegend = false;

  // Used to control if my own results are shown
  unEnrolledStudentsAreShown = false;

  // Used to control the recalculate scores modal
  recalculateStudentScoresVisible = false;
  recalculateRequestComplete = false;
  recalculateRequestPending = false;
  recalculateRequestFailed = false;
  recalculateScoresOkText = 'Recalculate Scores for Students';
  recalculateScoresCancelText = 'No Thanks..';
  recalculateStudentScoresModalTitle = 'Re-Calculate Student Scores';
  recalculateScoreInstancesThatFailed: string[] = [];

  // Controls Features
  private user: IUser;
  showRecalculateScores = false;

  // Scores
  studentScores: IScore<any>[];
  scoresExportLabel: string;
  showExportAllSubmissionScores = false;

  constructor(
    private featuresService: FeatureService,
    private scoresService: ScoresService,
    private store: Store,
    private exportService: ExportService
  ) { }

  ngOnInit(): void {

    this.subscriptions.push(this.store.select(selectCurrentCourse)
      .subscribe(course => {
        this.store.dispatch(loadProductScoringConfiguration());
        this.scoresExportLabel = this.scoresService.getScoreHeaderLabel(course.productCode);
        if (this.scoresExportLabel.toLocaleLowerCase() === 'chapter') {
          this.showExportAllSubmissionScores = true;
        }
      }));

    this.subscriptions.push(this.store.select(currentUser).subscribe(user => {
      if (user?.jwt.length > 0) {
        this.user = user;
        this.setupFeatures();
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngAfterViewInit() {
    // Debounce the search
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.scoresTable.filterStudents();
        })
      ).subscribe();
  }

  public handleScoreTypeChange(checked: boolean) {
    if (checked) {
      this.scoreType = 'percent';
    } else {
      this.scoreType = 'points';
    }
  }

  private setupFeatures() {
    this.featuresService.features$.subscribe(() => {
      // Enable or Disable Recalculate Scores | Future as feature flag
      this.showRecalculateScores = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.user, 'allowScoreRecalculation');
    });
  }

  refreshStudentResults() {
    this.log('Refreshing Student Data');
    this.courseProductResults = [];
    this.updateResults.emit();
  }

  // UTILITY
  private log(message: string): void {
    if (console) { console.log(message); }
  }

  // MODALS
  recalculateStudentScores(): void {
    if (!this.recalculateRequestPending && !this.recalculateRequestComplete) {
      this.recalculateRequestPending = true;
      this.recalculateScoresOkText = null;
      this.recalculateScoresCancelText = null;
      this.recalculateStudentScoresModalTitle = 'Requesting Recalculation of Scores';

      // Get the ones that failed, or the full list
      const simulationInstanceIds: string[] = this.recalculateScoreInstancesThatFailed;

      if (!this.recalculateRequestFailed) {
        this.courseProductResults.forEach(s => {
          simulationInstanceIds.push(s.simulationInstanceId);
        });
      }

      this.scoresService.recalculateCourseProductScores(simulationInstanceIds).subscribe(failedInstanceIds => {
        this.recalculateScoreInstancesThatFailed = failedInstanceIds || [];

        // remove all scores for those that didn't fail...
        this.courseProductResults.forEach(sd => {
          if (!this.recalculateScoreInstancesThatFailed.includes(sd.simulationInstanceId)) {
            sd.moduleScores = [];
          }
        });

        this.refreshStudentResults();

        this.recalculateRequestPending = false;
        this.recalculateRequestFailed = this.recalculateScoreInstancesThatFailed.length > 0;
        this.recalculateRequestComplete = true;
        this.recalculateScoresOkText = 'Return to Portal';
        this.recalculateStudentScoresModalTitle = 'Recalculation of Scores is Processing';
      }, error => {
        this.recalculateScoreInstancesThatFailed = simulationInstanceIds;
        this.recalculateRequestFailed = true;
        this.recalculateRequestPending = false;
        this.recalculateRequestComplete = false;
        this.recalculateScoresOkText = 'Thats a fail, Retry?';
        this.recalculateScoresCancelText = 'I will try later...';
        this.recalculateStudentScoresModalTitle = 'Recalculation of Scores is Processing';
      });
    } else {
      this.resetRecalculateModal();
    }
  }

  resetRecalculateModal(): void {
    this.recalculateScoresCancelText = 'No Thanks..';
    this.recalculateScoresOkText = 'Recalculate Scores for Students';
    this.recalculateScoreInstancesThatFailed = [];
    this.recalculateRequestComplete = false;
    this.recalculateStudentScoresVisible = false;
    this.recalculateRequestFailed = false;
  }

  export(): void {
    if (this.scorableItems) {
      const courseScores = {
        courseProductResults: this.courseProductResults,
        scorableItems: this.scorableItems,
        allScores: this.allScores,
        unEnrolledStudentsAreShown: this.unEnrolledStudentsAreShown,
        scoreType: this.scoreType
      } as ICourseScores;
      this.exportService.export(courseScores);
    }
  }

  exportAllScorableItems(): void {
    if (this.scorableItems) {
      const courseScores = {
        courseProductResults: this.courseProductResults,
        scorableItems: this.scorableItems,
        allScores: this.allScores,
        unEnrolledStudentsAreShown: this.unEnrolledStudentsAreShown,
        scoreType: this.scoreType
      } as ICourseScores;
      this.exportService.exportAllScorableItems(courseScores);
    }
  }
}
