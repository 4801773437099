import { createAction, props } from '@ngrx/store';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';

const prefix = '[InstructorPortal Config]';

export const loadInstructorPortalConfig = createAction(`${prefix} Load`, props<{ courseCode: string, productCode: string; }>());

export const setInstructorPortalConfig = createAction(`${prefix} Set`, props<{ newConfig: IInstructorPortalConfiguration; }>());

export const loadInstructorPortalConfigFromStorage = createAction(`${prefix} Load from Storage`);
