import { createReducer, on, Action } from '@ngrx/store';
import { IStudent } from '../../models/student.model';
import { setEnrollments, setEnrollmentsFailed, getLmsEnrollmentsFailed, getLmsEnrollments, getLmsEnrollmentsSucceeded, removeStudentEnrollmentSuccess, removeStudentEnrollmentFailed, removeStudentEnrollment } from './enrollment.actions';

export interface IEnrollmentState {
  students: IStudent[];
  isLoading: boolean;
  isFaulted: boolean;
  errorMessage?: string;
}

export const initialState: IEnrollmentState = {
  students: null,
  isLoading: true,
  isFaulted: false,
};

const reducer = createReducer(initialState,
  on(setEnrollments, (state, { students }) => {
    return { ...state, students, isLoading: false, isFaulted: false, errorMessage: null };
  }),
  on(setEnrollmentsFailed, (state, { errorMessage }) => {
    return { ...state, errorMessage, isLoading: false, isFaulted: true };
  }),
  on(getLmsEnrollments, (state) => {
    return { ...state, isLoading: true, isFaulted: false, errorMessage: null };
  }),
  on(getLmsEnrollmentsSucceeded, (state, { students }) => {
    return { ...state, students, isLoading: false, isFaulted: false, errorMessage: null };
  }),
  on(getLmsEnrollmentsFailed, (state, { errorMessage }) => {
    return { ...state, errorMessage, isSyncing: false, isFaulted: true };
  }),
  on(removeStudentEnrollmentSuccess, (state, { studentIdentification }) => {
    const updatedStudents = [...state.students].filter(s => s.identifier !== studentIdentification);
    return { ...state, students: updatedStudents, isLoading: false, isFaulted: false, errorMessage: null };
  }),
  on(removeStudentEnrollment, (state, { studentIdentification }) => {
    const updatedStudents = [...state.students].filter(s => s.identifier !== studentIdentification);
    return { ...state, students: updatedStudents, isLoading: false, isFaulted: false, errorMessage: null };
  }),
  on(removeStudentEnrollmentFailed, (state, { errorMessage }) => {
    return { ...state, errorMessage, isLoading: false, isFaulted: true };
  }),
);

export function enrollmentReducer(user: IEnrollmentState, action: Action) {
  return reducer(user, action);
}
