import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { loadInstructorResources, setInstructorResources, setInstructorResourcesFailed } from './instructor-resource.actions';
import { InstructorResourcesService } from '../../services/instructor-resources/instructor-resources.service';

@Injectable()
export class InstructorResourcesEffects {
  loadInstructorResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInstructorResources),
      mergeMap(() =>
        this.instructorResourceService.getInstructorResources()
          .pipe(
            map(instructorRes => setInstructorResources({ instructorResources: instructorRes })),
            catchError((err) =>
              of(
                setInstructorResourcesFailed({
                  errorMessage: 'Failed to Get Instructor Resources',
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private instructorResourceService: InstructorResourcesService
  ) { }

}
