import { Component, Input, Output, EventEmitter, OnChanges, SimpleChange, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ICourseSettings, IDueDateTimers, IDueDateTimersSetting, IModuleSetting, IModuleSettings, IPageSetting, TimerAction, IPeerReviewTableRow } from '../../models/simulation/simulation-settings.model';
import { NzModalService } from 'ng-zorro-antd';
import * as moment from 'moment-timezone';
import { IStudentLocation } from '../../models/simulation/location.model';
import { ICourseProductTemplate, ICourseProductTemplateModule } from '@stukent/mimic-core';
import { ICourseProductInformation, IStudentScoreSummary } from '../../models/simulation/scores/simulation-results';
import { IModule } from '../../models/module.model';
import { IElementSettings, IQuestionBankSettings } from '../../models/scorable-items/scorable-items-state.model';
import { Store } from '@ngrx/store';
import { startStopDueDateTimers } from '../../reducers/scorable-items/scorable-items.actions';
import { IUser } from '@stukent/user';
import { selectLmsIntegration } from 'src/app/reducers/lms-integration/lms-integration.selectors';
import { Subscription } from 'rxjs';

interface IScoreType {
  name: string;
  value: string;
}

enum ChangeState {
  unchanged,
  changed,
  saved,
  failed
}

enum ProductType {
  unknown,
  edifyApp,
  mimicApp
}

interface IScoreType {
  name: string;
  value: string;
  enabled?: boolean;
}

@Component({
  selector: 'app-simulation-settings',
  templateUrl: './simulation-settings.component.html',
  styleUrls: ['./simulation-settings.component.scss']
})
export class SimulationSettingsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() courseProductInformation: ICourseProductInformation;
  @Input() courseProductTemplate: ICourseProductTemplate;
  @Input() studentLocations: IStudentLocation[];
  @Input() courseProductResults: IStudentScoreSummary[];
  @Input() allowModifyForUsers: string[];
  @Input() currentUser: IUser;
  @Input() questionBankSettings: IQuestionBankSettings[];
  @Input() pageSettings: IPageSetting[] = [];
  @Input() elementSettings: IElementSettings[];
  @Input() showResultSummaryToggle = false;

  @Output() courseSettingsUpdated = new EventEmitter<{
    timezone: string;
    scoreFormat: string;
    courseSettings: ICourseSettings;
    modulesSettings: IModuleSetting[];
    questionBankSettings: IQuestionBankSettings[];
    pageSettings: IPageSetting[];
    elementSettings: IElementSettings[];
  }>();

  // These are for convenience vs. coding this.courseProductTemplate.properties...
  private originalRoundOrder: { id: string, order: number }[] = [];
  private originalCourseSettings: ICourseSettings;
  private originalModules: IModule[];

  // Lms Integration
  private linkedLmsProducts: string[];
  private lmsSubscription: Subscription;

  public productIsLinkedToLms = false;

  // Due time variables
  private dueDateTimersToStart: IQuestionBankSettings[] = [];
  private dueDateTimersToStop: IQuestionBankSettings[] = [];
  private originalQuestionBankSettings: IQuestionBankSettings[] = [];

  public scoreItemsOnDueDate = false;
  public allowPostAfterDueDate = false;
  public scoreFormat: string;
  public timezone: string;
  public questionBankSettingsChanged = false;

  // Peer review
  public peerReviewTableRows = [];
  public originalPeerReviewTableRows = [];
  public elementsToUpdate = [];
  public peerReviewDateErrorMessage: string;
  public courseHasPeerReview = false;

  // Expose the Enums
  public ChangeState = ChangeState;
  public ProductType = ProductType;

  // Other
  private enableQuizzes: boolean;
  private savingDefaultTimezone = false;

  public productType: ProductType = ProductType.unknown;
  public changeState: ChangeState;
  public modulesForChild = [];
  public showResultsSummary = false;
  public showQuestionBankAnswers = false;
  public courseSettings: ICourseSettings;
  public modules: IModule[];
  public timezones: string[] = moment.tz.names();
  public scoreTypes: IScoreType[] = [
    {
      value: 'Percent',
      name: 'Percentage (100%)',
      enabled: true
    },
    {
      value: 'Point',
      name: 'Points (100 / 100)',
      enabled: true
    }
  ];

  constructor(
    private store: Store,
    private nzModalService: NzModalService,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.lmsSubscription = this.store.select(selectLmsIntegration).subscribe(course => {
      if (course?.linkedProducts) {
        this.linkedLmsProducts = course.linkedProducts;

        if (this.courseProductTemplate && course?.linkedProducts.includes(this.courseProductTemplate.productCode)) {
          this.productIsLinkedToLms = true;
          this.allowPostAfterDueDate = false;
          this.scoreItemsOnDueDate = false;
        }
      }
    });

    this.setSettingsFromTemplate();
    this.buildPeerReviewTableRows();
  }

  ngOnDestroy() {
    if (this.lmsSubscription) {
      this.lmsSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (!this.productIsLinkedToLms && this.courseProductTemplate && this.linkedLmsProducts?.includes(this.courseProductTemplate.productCode)) {
      this.productIsLinkedToLms = true;
      this.allowPostAfterDueDate = false;
      this.scoreItemsOnDueDate = false;
    }

    if (!this.originalQuestionBankSettings.length || changes.questionBankSettings) {
      this.originalQuestionBankSettings = this.questionBankSettings;
    }

    if (!this.studentLocations && changes.studentLocations) {
      this.log('Settings: Student Locations changed');
      if (changes.studentLocations.currentValue) {
        this.studentLocations = JSON.parse(JSON.stringify(changes.studentLocations.currentValue));
        this.setSettingsFromTemplate();
      }
    }

    if (changes.courseProductTemplate && changes.courseProductTemplate.currentValue) {
      this.log('Settings: Course Product Template Changed');
      this.courseProductTemplate = JSON.parse(JSON.stringify(changes.courseProductTemplate.currentValue));
      this.setSettingsFromTemplate();
    }

    if (changes.currentUser && changes.currentUser.currentValue) {
      this.log('Settings: Current In User Changed');
      this.currentUser = JSON.parse(JSON.stringify(changes.currentUser.currentValue));
      this.setSettingsFromTemplate();
    }

    if (changes.courseProductInformation && changes.courseProductInformation.currentValue) {
      this.log('Settings: Course Product Information Changed');
      this.courseProductInformation = JSON.parse(JSON.stringify(changes.courseProductInformation.currentValue));
      this.setSettingsFromTemplate();
    }

    if (!this.courseProductResults && changes.courseProductResults) {
      if (changes.courseProductResults.currentValue) {
        this.log('Settings: Course Summaries changed');
        this.courseProductResults = JSON.parse(JSON.stringify(changes.courseProductResults.currentValue));
        this.setSettingsFromTemplate();
      }
    }


    if (this.courseProductTemplate) {
      // Don't show peer review settings if no review policy
      this.courseHasPeerReview = this.courseProductTemplate.modules.some(m => {
        return m.pages.some(p => {
          return p.elements.some(e => {
            return e?.config?.reviewPolicy ? true : false;
          });
        });
      });
    }
  }

  private buildPeerReviewTableRows() {
    // Make sure we don't build more
    this.peerReviewTableRows = [];
    this.courseProductTemplate?.modules?.forEach((m, i) => {
      // find the module from the parent to help disable the peer reveiw based on
      // the module enable setting.
      const module = this.modules?.find(mod => mod.id === m.id);
      m.pages.forEach((p) => {
        p.elements.forEach((e) => {
          if (e?.config?.reviewPolicy) {
            const peerReviewTableRow = {
              id: e.id,
              name: e.config.title,
              round: `Appears in Round ${i}, ${m.displayName}`,
              endDate: e.properties?.endDate || null,
              moduleId: m.id,
              pageId: p.id,
              error: false,
              roundEnabled: module?.settings?.enabled
            } as IPeerReviewTableRow;
            this.peerReviewTableRows.push(peerReviewTableRow);
          }
        });
      });
    });
    this.originalPeerReviewTableRows = JSON.parse(JSON.stringify(this.peerReviewTableRows));
  }

  private log(message: string): void {
    if (console) { console.log(message); }
  }

  private setSettingsFromTemplate() {
    if (!this.courseProductTemplate) {
      return;
    }

    this.productType = (this.courseProductTemplate.productCode.includes('EFY-')) ? ProductType.edifyApp : ProductType.mimicApp;

    // some old courses do not have a properties property, so let's make an empty one, so we can update them
    this.courseProductTemplate.properties = this.courseProductTemplate?.properties as ICourseSettings;
    this.originalCourseSettings = this.courseProductTemplate.properties;
    this.courseSettings = JSON.parse(JSON.stringify(this.originalCourseSettings));

    this.timezone = this.courseProductTemplate.timezone;
    this.scoreFormat = this.courseProductTemplate.scoreFormat;

    if (!this.scoreFormat) {
      this.scoreFormat = 'Percent';
    }

    if (!this.timezone) {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (!this.courseSettings.scoreOnDueDateEnabled) {
      this.courseSettings.scoreOnDueDateEnabled = this.scoreItemsOnDueDate;
    }
    else {
      this.scoreItemsOnDueDate = this.productIsLinkedToLms ? false : this.courseSettings.scoreOnDueDateEnabled;
    }


    if (this.courseSettings.allowSubmissionsAfterDueTime === undefined) {
      this.courseSettings.allowSubmissionsAfterDueTime = false;
    }
    // The front end is looking for false to equal true, so this inverts it to properly deal with it
    this.allowPostAfterDueDate = !this.productIsLinkedToLms && !this.courseSettings.allowSubmissionsAfterDueTime;

    // If the property doesn't exist, it will use the default.
    if (this.courseSettings.showResultsSummary) {
      // Set it to the settign value
      this.showResultsSummary = this.courseSettings.showResultsSummary;
    }

    if (this.courseSettings.showQuestionBankAnswers) {
      this.showQuestionBankAnswers = this.courseSettings.showQuestionBankAnswers;
    }

    this.modules = this.courseProductTemplate.modules.map((m, i: number) => ({
      id: m.id,
      order: i,
      title: m.displayName,
      settings: this.getSettingsFromProperties(m),
      pastStartDate: this.moduleHasStarted(m),
      hasUsers: this.moduleHasUsers(m),
      isConclusion: this.isConclusionModule(m),
      pages: m.pages
    }
    ));

    // Generate pageSettings for Edify Quizzes
    // Only runs if page settings is empty
    if (this.pageSettings.length < 1) {
      this.courseProductTemplate.modules.forEach(module => {
        module.pages.forEach(page => {
          if (page?.properties?.typeName === 'quiz') {
            const quizPageSettings = {
              moduleId: module.id,
              id: page.id,
              settings: page.properties
            } as IPageSetting;
            // generate the page settings, add to page settings
            // only add to page settings if it doesn't already exist
            const found = this.pageSettings.find(x => x.id === page.id);
            if (!found) {
              this.pageSettings.push(quizPageSettings);
            }
          }
        });
      });
    }

    this.originalModules = JSON.parse(JSON.stringify(this.modules));

    this.originalRoundOrder = this.modules.map((r: IModule) => ({ id: r.id, order: r.order }));

    if (!this.savingDefaultTimezone && (!this.courseProductTemplate.properties)) {
      this.setDefaultTimezone();
    }

    this.buildPeerReviewTableRows();
  }

  private isConclusionModule(module: ICourseProductTemplateModule): boolean {
    const moduleInfo = this.courseProductInformation?.modules?.find(m => m.moduleId === module.id);
    return moduleInfo?.isConclusion ?? false;
  }

  private getSettingsFromProperties(module: ICourseProductTemplateModule): IModuleSettings {
    const properties = module.properties ?? {};

    const settings = JSON.parse(JSON.stringify(properties)) as IModuleSettings;

    settings.allowDisableEnable = this.allowEnableDisableModule(properties, module.id);

    settings.enabled = !settings.disableRound;

    return settings;
  }

  private allowEnableDisableModule(moduleProperties: any, moduleId: string): boolean {

    if (this.currentUser && this.currentUser.roles?.includes('admin')) {
      return true;
    }

    // Default Allow Toggle Enabled or Disabled to true except for the isIntroduction and isConclusion
    const thisModule = this.courseProductInformation?.modules?.find(m => m.moduleId === moduleId);
    const isIntroOrConclusion = (thisModule?.isConclusion || thisModule?.isIntroduction);
    if (isIntroOrConclusion) {
      return false;
    }

    if (moduleProperties?.allowToggleRound == null) {
      return true;
    } else {
      return moduleProperties.allowToggleRound;
    }
  }

  private moduleHasStarted(module: ICourseProductTemplateModule): boolean {

    if (this.currentUser && this.currentUser.roles?.includes('admin')) {
      return false;
    }

    if (module.properties?.startDate) {
      const moduleStartDate = this.removeTime(module.properties?.startDate);
      const currentDate = new Date();
      const isPastStart = currentDate > moduleStartDate;

      if (isPastStart) {
        return true;
      }
    }

    return false;
  }

  private moduleHasUsers(module: ICourseProductTemplateModule): boolean {

    if (this.currentUser && this.currentUser.roles?.includes('admin')) {
      return false;
    }

    if (!this.allowModifyForUsers) {
      this.allowModifyForUsers = [];
    }
    // Filter out Logged In User
    this.allowModifyForUsers.push(this.currentUser.email);

    // Check if the Locations has users
    // Filter out allowModifyForUsers
    // ALWAYS filter out @stukent.com emails
    if (this.studentLocations) {
      const studentStarted = this.studentLocations.some(
        l => l.location?.moduleId === module.id &&
          (!l.studentIdentifier.includes('@stukent.com') || !this.allowModifyForUsers.includes(l.studentIdentifier))
      );

      if (studentStarted) {
        return true;
      }
    }

    // Check each students module scores to see if this one has Results or is Complete
    // Filter out allowModifyForUsers
    // ALWAYS filter out @stukent.com emails
    if (this.courseProductResults) {

      const results = this.courseProductResults.filter(
        s => !s.identifier.includes('@stukent.com') || !this.allowModifyForUsers.includes(s.identifier)
      );

      const studentCompleted = results.some(
        s => s.moduleScores.some(m => m.id === module.id && (m.hasResults === true || m.isComplete === true))
      );

      if (studentCompleted) {
        return true;
      }
    }

    return false;
  }

  validateSettings(): void {
    let valid = true;
    let message = 'We were unable to save your settings.';

    // validations
    // const enabledModules = [...this.modules].splice(0, this.modules.length - 1);
    if (this.modules.filter(m => !m.settings.disableRound).length === 0) {
      valid = false;
      message += ' Please enable at least 1 product round.';
    }

    if (valid) {
      this.saveSettings();
    } else {
      this.nzModalService.error({
        nzTitle: 'Uh-Oh!',
        nzContent: message,
        nzMaskClosable: true
      });
    }
  }

  checkForScorableItemsChanges(newSettings: IQuestionBankSettings[]): void {
    this.questionBankSettingsChanged = false;

    // Check if any of the settings since the last save are different or if new settings were added
    for (const qb of newSettings) {
      const found = this.originalQuestionBankSettings.find(x => x.questionBankId === qb.questionBankId);
      if (!found) {
        this.questionBankSettingsChanged = true;
        break;
      }
      else {
        if (found.settings.dueDate !== qb.settings.dueDate ||
          found.settings.enabled !== qb.settings.enabled ||
          found.settings.timeLimit !== qb.settings.timeLimit) {
          this.questionBankSettingsChanged = true;
          break;
        }
      }
    }

    this.checkForChanges();

    if (this.questionBankSettingsChanged) {
      this.handleDueDateChanges(newSettings);
    }

    this.questionBankSettings = newSettings;
  }

  public pageSettingsChanged(pageSettings: IPageSetting) {
    // Make a deep copy to edit
    this.pageSettings = JSON.parse(JSON.stringify(this.pageSettings));

    const pageSettingsToUpdate = this.pageSettings.find(x => x.id === pageSettings.id);

    if (pageSettingsToUpdate) {
      const indexToUpdate = this.pageSettings.indexOf(pageSettingsToUpdate);
      this.pageSettings[indexToUpdate].settings = pageSettings.settings;

      this.checkForChanges();
    }
  }

  public courseSettingsChanged(courseSettings: ICourseSettings) {
    this.courseSettings = courseSettings;
    this.checkForChanges();
  }

  rowChanged(row: IPeerReviewTableRow) {
    const indexOfRow = this.peerReviewTableRows.findIndex(r => r.id === row.id);
    this.peerReviewTableRows[indexOfRow] = row;

    const validDate = this.validPeerReviewDate(row);

    if (validDate) {
      this.updateSetting(row.id, row.moduleId, row.pageId, 'endDate', row.endDate);
      this.checkForChanges();
    }
  }

  handleDueDateChanges(newSettings: IQuestionBankSettings[]) {
    // Find scorable items whose due dates have changed
    let changedDueDateSettings: IQuestionBankSettings[] = [];
    if (this.questionBankSettings?.length) {
      changedDueDateSettings = newSettings.filter(current => {
        const foundPrevious = this.questionBankSettings.find(prev => prev.questionBankId === current.questionBankId);
        if (foundPrevious && foundPrevious.settings.dueDate !== current.settings.dueDate) {
          return current;
        }
      });
    }
    // or set all that have a due date if no previous settings have been set
    else {
      changedDueDateSettings = newSettings.filter(qb => qb.settings.dueDate);
    }

    // NOTE: While changedDueDateSettings is an array, right now it will only ever have one change at a time, because the child component emits when just one has changed
    if (changedDueDateSettings.length) {
      // A null due date means that it has been cleared and needs to be stopped
      // Additionally, a disabled row means that its timer needs to be stopped
      if (changedDueDateSettings.every(x => x.settings.dueDate === null) || changedDueDateSettings.every(x => x.settings.enabled === false)) {
        // 'dueDateTimersToStop' is concatenated to 'changedDueDateSettings' so that the most recent changes are first in the array, then filtered to have only unique entries (remove old items in array)
        this.dueDateTimersToStop = changedDueDateSettings.concat(this.dueDateTimersToStop).filter((value, index, self) => {
          return self.findIndex(x => x.questionBankId === value.questionBankId) === index;
        });

        // remove the timers that we are stopping from the 'timersToStart' array
        changedDueDateSettings.forEach(setting => this.dueDateTimersToStart = this.dueDateTimersToStart.filter(timer => timer.questionBankId !== setting.questionBankId));
      }
      else {
        // 'dueDateTimersToStart' is concatenated to 'changedDueDateSettings' so that the most recent changes are first in the array, then filtered to have only unique entries (remove old items in array)
        this.dueDateTimersToStart = changedDueDateSettings.concat(this.dueDateTimersToStart).filter((value, index, self) => {
          return self.findIndex(x => x.questionBankId === value.questionBankId) === index;
        });

        // remove the timers that we are starting from the 'timersToStop' array
        changedDueDateSettings.forEach(setting => this.dueDateTimersToStop = this.dueDateTimersToStop.filter(timer => timer.questionBankId !== setting.questionBankId));
      }
    }
  }

  checkForChanges(): void {

    // Score Format Changed
    if (this.scoreFormat !== this.courseProductTemplate.scoreFormat) {
      this.changeState = ChangeState.changed;
    }
    // Enable Quiz Toggle Changed
    if (this.courseSettings.enableQuizzes !== this.enableQuizzes) {
      this.changeState = ChangeState.changed;
    }

    // Timezone Changed
    if (this.timezone !== this.courseProductTemplate.timezone) {
      this.changeState = ChangeState.changed;
    }

    // Allow post after due date changed
    if (this.courseSettings?.allowSubmissionsAfterDueTime
      !== (this.originalCourseSettings?.allowSubmissionsAfterDueTime ?? false)) {
      this.changeState = ChangeState.changed;
    }

    // Score on due date toggle changed
    if (this.courseSettings.scoreOnDueDateEnabled !== this.originalCourseSettings.scoreOnDueDateEnabled) {
      this.changeState = ChangeState.changed;
    }

    // Peer review feedback changed
    if (this.courseSettings.allowPeerReviewFeedbackDueDates !== this.originalCourseSettings.allowPeerReviewFeedbackDueDates) {
      this.changeState = ChangeState.changed;
    }

    // Peer review feedback changed
    if (this.courseSettings.allowEarlyDistribution !== this.originalCourseSettings.allowEarlyDistribution) {
      this.changeState = ChangeState.changed;
    }

    // Peer review feedback changed
    if (JSON.stringify(this.peerReviewTableRows) !== JSON.stringify(this.originalPeerReviewTableRows)) {
      this.changeState = ChangeState.changed;
    }

    // Show Results Summary changed
    if (this.courseSettings.showResultsSummary !== this.originalCourseSettings.showResultsSummary) {
      this.changeState = ChangeState.changed;
    }

    // Show Question Bank Answers changed
    if (this.courseSettings.showQuestionBankAnswers !== this.originalCourseSettings.showQuestionBankAnswers) {
      this.changeState = ChangeState.changed;
    }

    // Round Order Changed
    if (this.changeState !== ChangeState.changed &&
      !this.originalRoundOrder.every((roundOrder: { id: string, order: number }, i: number) =>
        this.modules.findIndex((r: IModule) => r.id === roundOrder.id) === i)) {
      this.changeState = ChangeState.changed;
    }

    // check for changes in any of the module settings
    if (this.changeState !== ChangeState.changed &&
      this.modules.some(m => {

        const originalSettings = this.originalModules.find(om => om.id === m.id).settings;
        const currentSettings = m.settings;

        let startDateChanged = false;
        if (currentSettings.startDate || originalSettings.startDate) {
          startDateChanged = currentSettings.startDate !== originalSettings.startDate;
        }

        let endDateChanged = false;
        if (currentSettings.endDate || originalSettings.endDate) {
          endDateChanged = currentSettings.endDate !== originalSettings.endDate;
        }

        const enabledChanged = currentSettings.enabled !== originalSettings.enabled;

        this.buildPeerReviewTableRows();

        if (!currentSettings.enabled) {
          // Remove the date if disabled...
          currentSettings.startDate = null;
          currentSettings.endDate = null;
          startDateChanged = true;
          endDateChanged = true;
        }

        return startDateChanged || endDateChanged || enabledChanged;

      })) {
      this.changeState = ChangeState.changed;
    }


    if (this.questionBankSettingsChanged) {
      this.changeState = ChangeState.changed;
    }

  }

  private saveSettings(): void {
    this.startStopDueDateTimers();

    // For new question bank settings change detection
    this.originalQuestionBankSettings = JSON.parse(JSON.stringify(this.questionBankSettings));

    const updatedModuleSettings: IModuleSetting[] = [];

    this.modules.forEach(m => {
      const updateModule = { moduleId: m.id, settings: JSON.parse(JSON.stringify(m.settings)) };
      updateModule.settings.disableRound = !updateModule.settings.enabled;

      // Remove deprecated settings
      delete updateModule.settings.excludeFromResults;

      // Remove stuff the user can't change
      delete updateModule.settings.allowToggleRound;
      delete updateModule.settings.allowDisableEnable;
      delete updateModule.settings.allowEndDate;

      // Remove stuff they can't set when disabled.
      if (!updateModule.settings.enabled) {
        if (updateModule.settings?.startDate) { updateModule.settings.startDate = null; }
        if (updateModule.settings?.endDate) { updateModule.settings.endDate = null; }
      }
      updatedModuleSettings.push(updateModule);
    });

    this.courseSettingsUpdated.emit({
      timezone: this.timezone,
      scoreFormat: this.scoreFormat,
      courseSettings: this.courseSettings,
      modulesSettings: updatedModuleSettings,
      questionBankSettings: this.questionBankSettings,
      pageSettings: this.pageSettings,
      elementSettings: this.elementSettings
    });

    // Avoid refresh as means to update originalCourseSettings;
    this.originalCourseSettings = this.courseSettings;
    this.courseProductTemplate.timezone = this.timezone;
    this.courseProductTemplate.scoreFormat = this.scoreFormat;

    // Reset change state
    this.changeState = ChangeState.unchanged;

  }

  public onShowResultsSummaryChange(): void {
    // Make a copy to enable editing of read only properties
    this.courseSettings = JSON.parse(JSON.stringify(this.courseSettings));

    this.courseSettings.showResultsSummary = this.showResultsSummary;
    this.checkForChanges();
  }

  public onShowStudentAnswersChange(): void {
    // Make a copy to enable editing of read only properties
    this.courseSettings = JSON.parse(JSON.stringify(this.courseSettings));

    this.courseSettings.showQuestionBankAnswers = this.showQuestionBankAnswers;
    this.checkForChanges();
  }

  public onTimeZoneSettingChange(): void {
    this.checkForChanges();
  }

  public onScoreFormatSettingChange(): void {
    this.checkForChanges();
  }

  public onSwitchSubmissionsAfterDueDate(): void {
    // Make a copy to enable editing of read only properties
    this.courseSettings = JSON.parse(JSON.stringify(this.courseSettings));

    this.courseSettings.allowSubmissionsAfterDueTime = !this.allowPostAfterDueDate;
    if (this.courseSettings.allowSubmissionsAfterDueTime) {
      this.scoreItemsOnDueDate = false;
      this.onCheckboxDueDateScoring();
    }
    this.checkForChanges();
  }

  public onEnableQuizzesSwitch(enableQuizzes: boolean): void {
    // Ensure local variable matched CPT Course Settings to check for changes properly
    this.enableQuizzes = this.courseSettings.enableQuizzes;

    // Make a copy to enable editing of read only properties
    this.courseSettings = JSON.parse(JSON.stringify(this.courseSettings));
    this.courseSettings.enableQuizzes = enableQuizzes;

    this.checkForChanges();

    // Update local variable for additional changes
    this.enableQuizzes = enableQuizzes;
  }


  public onCheckboxDueDateScoring(): void {
    // Make a copy to enable editing of read only properties
    this.courseSettings = JSON.parse(JSON.stringify(this.courseSettings));

    this.courseSettings.scoreOnDueDateEnabled = this.scoreItemsOnDueDate;

    this.checkForChanges();

    if (this.scoreItemsOnDueDate) {
      // Start timers that have a due date, stop those that don't
      // Check due dates to make sure they are not old
      this.dueDateTimersToStart = this.questionBankSettings.filter(qb => qb.settings.dueDate
        && (new Date(qb.settings.dueDate).getTime() > new Date().getTime()));
      this.dueDateTimersToStop = this.questionBankSettings.filter(qb => !qb.settings.dueDate);
    }
    else {
      // Stop all timers
      this.dueDateTimersToStop = this.questionBankSettings;
      this.dueDateTimersToStart = [];
    }
  }

  private startStopDueDateTimers() {
    const startTimers = this.getTimersToStart();
    const stopTimers = this.getTimersToStop();

    const timers: IDueDateTimers = {
      scoreOnDueDate: this.scoreItemsOnDueDate,
      dueDateTimers: startTimers.concat(stopTimers),
      allowSubmissionsAfterDueTime: !this.allowPostAfterDueDate
    };

    this.store.dispatch(startStopDueDateTimers({ timers }));
  }

  private getTimersToStart() {
    // Although this shouldn't happen, Double check that the timers have a due date
    const filteredSettings = this.dueDateTimersToStart.filter(setting => setting.settings.dueDate);

    if (!filteredSettings.length) {
      return [];
    }

    const timersToStart: IDueDateTimersSetting[] = [];
    filteredSettings.forEach(qb => {
      timersToStart.push({
        moduleId: qb.moduleId,
        elementId: qb.questionBankId,
        dueDate: qb.settings.dueDate,
        timerAction: TimerAction.Start
      });
    });

    this.dueDateTimersToStart = [];

    return timersToStart;
  }

  private getTimersToStop() {
    if (!this.dueDateTimersToStop.length) {
      return [];
    }

    const timersToStop: IDueDateTimersSetting[] = [];
    this.dueDateTimersToStop.forEach(qb => {
      timersToStop.push({
        moduleId: qb.moduleId,
        elementId: qb.questionBankId,
        dueDate: qb.settings.dueDate,
        timerAction: TimerAction.Stop
      });
    });

    this.dueDateTimersToStop = [];

    return timersToStop;
  }

  updateDate(date: string | Date, dateToUpdate: Date) {
    dateToUpdate = this.removeTime(date);
    this.checkForChanges();
  }

  private removeTime(date: string | Date): Date {
    date = date instanceof Date ? date : new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  private setDefaultTimezone(): void {
    this.savingDefaultTimezone = true;
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.saveSettings();
    // Hide the changed notice
    this.changeState = ChangeState.unchanged;
  }

  public moduleChange(module: IModule) {
    const peerReviewRow = this.peerReviewTableRows.find(r => r.moduleId === module.id);
    const moduleIndex = this.modules.findIndex(m => m.id === module.id);

    this.modules[moduleIndex] = module;
    this.modulesForChild = JSON.parse(JSON.stringify(this.modules));

    this.validPeerReviewDate(peerReviewRow);

    this.changeRef.detectChanges();
    this.checkForChanges();
  }

  private updateSetting(elementId: string, moduleId: string, pageId: string, settingName: string, value: any) {

    let element = this.peerReviewTableRows?.map(e => {
      return {
        moduleId: e.moduleId,
        pageId: e.pageId,
        id: e.id,
        settings: {
          endDate: e.endDate
        }
      } as IElementSettings;
    }).find(s => s.id === elementId);

    if (element) {
      element = JSON.parse(JSON.stringify(element));
    } else {
      element = this.createNewSettings(elementId, moduleId, pageId);
    }

    element.settings[settingName] = value;

    // If element is already in array, update the array with the new one
    if (this.elementsToUpdate.find(e => e.id === element.id)) {
      const elementIndex = this.elementsToUpdate.findIndex(e => e.id === element.id);
      this.elementsToUpdate[elementIndex] = element;
    } else {
      // If element is NOT already in the array, add the element
      this.elementsToUpdate.push(element);
    }

    this.elementSettings = [...this.elementsToUpdate];
  }

  private createNewSettings(id: string, moduleId: string, pageId: string): IElementSettings {
    return {
      moduleId,
      pageId,
      id,
      settings: {}
    };
  }

  private validPeerReviewDate(row: IPeerReviewTableRow): boolean {
    const module = this.modules.find(m => row?.moduleId === m.id);

    if (module) {
      if (row?.endDate && new Date(row?.endDate) < new Date(module.settings?.startDate)) {
        row.error = true;
        this.peerReviewDateErrorMessage = 'Due date can not be before the start date of the round';
        this.changeState = ChangeState.unchanged;
        return false;
      } else {
        row.error = false;
        this.peerReviewDateErrorMessage = null;
        this.changeState = ChangeState.changed;
        return true;
      }
    }
  }
}
