import { Action, createReducer, on } from '@ngrx/store';
import { loadInstructorPortalConfigFromStorage, setInstructorPortalConfig } from './config.actions';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';

export interface IInstructorPortalConfigState {
  config: IInstructorPortalConfiguration;
}

export const initialState: IInstructorPortalConfigState = {
  config: {
    isLoaded: false,
    mimicAppUrls: []
  }
};

// This is a temporary fix to allow simulation elements to access the state in the format they expect it.
export const initialSimConfigState: IInstructorPortalConfiguration = {
  isLoaded: false,
  mimicAppUrls: []
};

const reducer = createReducer(initialState,
  // tslint:disable-next-line: no-shadowed-variable
  on(setInstructorPortalConfig, (state, { newConfig }) => {
    const newState = JSON.parse(JSON.stringify(newConfig));
    // TODO - Unhack this.  The model has changed in mimic-core
    newState.isLoaded = true;
    newState.config = newState;
    return newState;
  }),
  on(loadInstructorPortalConfigFromStorage, () => {
    if (!localStorage.config) {
      return initialState;
    }
    return JSON.parse(localStorage.config);
  }),
);

export function instructorPortalConfigReducer(state: IInstructorPortalConfigState, action: Action) {
  return reducer(state, action);
}
