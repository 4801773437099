import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, Effect } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { ClassListService } from '../../services/enrollments/enrollments.service';
import {
  loadEnrollments,
  setEnrollments,
  setEnrollmentsFailed,
  getLmsEnrollmentsFailed,
  getLmsEnrollments,
  getLmsEnrollmentsSucceeded,
  removeStudentEnrollmentSuccess,
  removeStudentEnrollment,
  removeStudentEnrollmentFailed,
} from './enrollment.actions';
import { EMPTY, of } from 'rxjs';
import { LmsIntegrationService } from '../../services/integration/lms/lmsIntegration.service';

@Injectable()
export class EnrollmentEffects {
  loadEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnrollments),
      mergeMap(() =>
        this.classListService.getEnrollments().pipe(
          map((students) => setEnrollments({ students })),
          catchError((err) =>
            of(
              setEnrollmentsFailed({
                errorMessage: 'Failed to Get Enrollments',
              })
            )
          )
        )
      )
    )
  );

  removeStudentEnrollment$ = createEffect(() => this.actions$.pipe(
    ofType(removeStudentEnrollment),
    mergeMap(({ studentIdentification }) =>
      this.classListService.removeStudentEnrollment(studentIdentification)
        .pipe(
          map(response => removeStudentEnrollmentSuccess({ studentIdentification })),
          catchError(() => EMPTY),
        )
    )
  ));

  startSyncLmsEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLmsEnrollments),
      mergeMap(() =>
        this.lmsIntegrationService.getEnrollments().pipe(
          map((students) => getLmsEnrollmentsSucceeded({ students })),
          catchError((err) =>
            of(
              getLmsEnrollmentsFailed({
                errorMessage: 'Failed to get Enrollments from LMS Platform',
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private classListService: ClassListService,
    private lmsIntegrationService: LmsIntegrationService
  ) { }
}
