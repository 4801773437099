import { NgModule } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { CourseComponent } from '../pages/course/course.component';
import { InstrcutorPortalComponent } from '../app.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: InstrcutorPortalComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: 'https://home.stukent.com/'
    }
  },
  {
    path: ':courseCode/:productCode',
    component: CourseComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot) => {
        const url = window.location.href.toLowerCase();
        const isProd = (url.indexOf('-staging') === -1 && url.indexOf('-next') === -1 && url.indexOf('//localhost') === -1);
        const redirectUrl =
          isProd ? (route.data as any).externalUrl : (route.data as any).externalUrl.replace('.stukent.com', '-next.stukent.com');
        window.location.href = redirectUrl.replace(':courseCode', route.params.courseCode) + '?reason=invalidRoute';
      }
    }
  ]
})
export class MainRoutingModule { }
