import { createAction, props } from '@ngrx/store';
import { ICourseProductTemplate } from '@stukent/mimic-core';
import { IElementSettings } from 'src/app/models/scorable-items/scorable-items-state.model';
import { ICourseSettings, IModuleSetting, IPageSetting } from '../../models/simulation/simulation-settings.model';

const prefix = '[Template]';

export const loadCourseProductTemplate = createAction(`${prefix} Load`);
export const setCourseProductTemplate = createAction(`${prefix} Set`, props<{ template: ICourseProductTemplate }>());

const scoringPrefix = '[Scoring Template]';
export const loadResultsTemplate = createAction(`${scoringPrefix} Load Results Template`);
export const setResultsTemplate = createAction(`${scoringPrefix} Set Results Template`, props<{ template: ICourseProductTemplate }>());

const settingsPrefix = `[Course Settings]`;
export const setCourseSettings = createAction(`${settingsPrefix} Set Course Settings`,
  props<{ productCode: string, courseSettings: ICourseSettings }>());

export const setModuleSettings = createAction(`${settingsPrefix} Set Module Settings`,
  props<{ productCode: string, moduleSettings: IModuleSetting[] }>());

export const setPageSettings = createAction(`${settingsPrefix} Set Page Settings`,
  props<{ productCode: string, pageSettings: IPageSetting[] }>());

export const setProductTemplateSettings = createAction(`${settingsPrefix} Set Product Settings`,
  props<{ productCode: string, timezone: string, scoreFormat: string, courseSettings: ICourseSettings, moduleSettings: IModuleSetting[], pageSettings: IPageSetting[], elementSettings: IElementSettings[] }>());

export const setOnlyCourseSettings = createAction(`${settingsPrefix} Set Only Course Settings`,
  props<{ productCode: string, courseSettings: ICourseSettings }>());

