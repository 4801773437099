import { createReducer, on, Action, On } from '@ngrx/store';
import { setCourseProductTemplate, setResultsTemplate, setOnlyCourseSettings, setModuleSettings, setCourseSettings } from './template.actions';
import { IModuleSetting } from '../../models/simulation/simulation-settings.model';
import { ICourseProductTemplate, ICourseProductTemplateModule } from '@stukent/mimic-core';

export interface ICourseProductTemplateState {
  templates: ICourseProductTemplate[];
  resultTemplates: ICourseProductTemplate[];
  isLoading: boolean;
}



export const initialState: ICourseProductTemplateState = {
  templates: [],
  resultTemplates: [],
  isLoading: true
};

const templateReducers: On<ICourseProductTemplateState>[] = [
  on(setCourseProductTemplate, (state, { template }) => {
    const newState = JSON.parse(JSON.stringify(state)) as ICourseProductTemplateState;
    newState.templates.push(template as ICourseProductTemplate);
    if ((newState.resultTemplates || []).length > 0) {
      newState.isLoading = false;
    }
    return newState;
  }),
  on(setResultsTemplate, (state, { template }) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.resultTemplates.push(template);
    if ((newState.templates || []).length > 0) {
      newState.isLoading = false;
    }
    return newState;
  }),
  on(setOnlyCourseSettings, (state, { courseSettings, productCode }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const newTemplate = newState.templates.find(template => template.productCode === productCode);

    if (newTemplate) {
      newTemplate.properties = courseSettings;
    }

    return newState;
  }),
  on(setModuleSettings, (state, { productCode, moduleSettings }: { productCode: string, moduleSettings: IModuleSetting[] }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const newTemplate = newState.templates.find(template => template.productCode === productCode);

    /* Module properties */
    moduleSettings.forEach(mSettings => {
      newTemplate.modules.forEach((cptModule: ICourseProductTemplateModule) => {
        if (cptModule.id === mSettings.moduleId) {
          Object.keys(mSettings.settings).forEach((sKey: string) => {
            cptModule.properties[sKey] = mSettings.settings[sKey];
          });
        }
      });
    });

    /* re-order modules */
    const tmpModules = newTemplate.modules;
    newTemplate.modules = moduleSettings.map((moduleSetting: IModuleSetting) => {
      return tmpModules.find((m: ICourseProductTemplateModule) => m.id === moduleSetting.moduleId);
    });

    return newState;
  }),
  on(setCourseSettings, (state, { productCode, courseSettings }: { productCode: string, courseSettings: { [key: string]: any } }) => {
    /* course properties */
    const newState = JSON.parse(JSON.stringify(state));
    const newTemplate: ICourseProductTemplate = newState.templates.find(template => template.productCode === productCode);

    // properties is optional but we are saving the timezone and scoreFormat as default, they need a place to live
    if (!newTemplate?.properties) {
      newTemplate.properties = {};
    }

    Object.keys(courseSettings).forEach((key: string) => {
      newTemplate.properties[key] = courseSettings[key];
    });
    return newState;
  }),
];

function initTemplateReducer() {
  return createReducer(initialState, ...templateReducers);
}

export function templateReducer(user: ICourseProductTemplateState, action: Action) {
  return initTemplateReducer()(user, action);
}
