import { createAction, props } from '@ngrx/store';
export enum Actions {
  RESET_SIM = '[Sim Management Api] Reset Sim',
  RESET_MODULE_SCORE = '[Sim Scoring Api] Reset Module Score',
  RESET_SCORES_BY_LOCTAION_IDS = '[Sim Scoring Api] Reset Scores By Location Ids',
  RESET_QUESTION_INSTANCE = '[Questions Api] Reset Question Instance',
  RESET_LOCATION = '[Sim Instance API] Change Location for Student',
  SCORE_ROUND_FOR_STUDENT = '[Sim Management Api] Score Module For Student',
  EMPTY = 'EMPTY'
}

// tslint:disable: max-line-length

export const resetSim = createAction(Actions.RESET_SIM, props<{ studentIdentifier: string, moduleId: string }>());
export const resetModuleScore = createAction(Actions.RESET_MODULE_SCORE, props<{ simulationInstanceId: string, moduleId: string }>());
export const resetScoresByLocationIds = createAction(Actions.RESET_SCORES_BY_LOCTAION_IDS, props<{ simulationInstanceId: string, locationIds: string[] }>());
export const resetQuestionInstance = createAction(Actions.RESET_QUESTION_INSTANCE, props<{ studentIdentifier: string, questionBankId: string, userInstanceCode: string }>());
export const resetLocation = createAction(Actions.RESET_LOCATION, props<{ studentIdentifier: string, moduleId: string, pageId: string, instanceId: string }>());
export const empty = createAction(Actions.EMPTY);
export const scoreModuleForStudent = createAction(Actions.SCORE_ROUND_FOR_STUDENT, props<{ studentIdentifier: string, moduleId: string }>());
