import { Injectable } from '@angular/core';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICourseProductScoresSummary, IScoresSummary } from '../../models/simulation/scores/student-work-summary.model';
import { IStudentLocation } from '../../models/simulation/location.model';
import { ICourseProductTemplate, ICourseProductTemplateModule } from '@stukent/mimic-core';
import { ICourseProductInformation, IModuleResultInformation, IStudentScoreSummary, ISummaryModuleScore } from '../../models/simulation/scores/simulation-results';
import { IStudent } from '../../models/student.model';
import { IUser } from '@stukent/user';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class ScoresService {

  config: IInstructorPortalConfiguration;
  course: ICourseState;
  edifyPrefix = 'EFY';
  legacyEdifyPrefix = 'EDIFY';
  isEdify = false;
  certPrefix = 'CERT';
  isCert = false;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  getStudentWorkSummary(): Observable<ICourseProductScoresSummary> {
    return this.http
      .get<ICourseProductScoresSummary>(`${this.config.serviceUrls.scoringManagement}/${this.course.productCode}/${this.course.courseCode}/summary`);
  }

  getStudentScores(studentIdentifier: string): Observable<any> {
    const url = `${this.config.serviceUrls.simulationScoring}/${this.course.productCode}/${this.course.courseCode}/user/${studentIdentifier}`;

    // TODO Need to understand why all modules need to be loaded. For now, load them all
    return this.http.get<any>(url);
  }

  getStudentsScores(): Observable<any> {
    const url = `${this.config.serviceUrls.scoringManagement}/${this.course.productCode}/${this.course.courseCode}/scores`;

    return this.http.get<any>(url);
  }

  getStudentLocations(): Observable<IStudentLocation[]> {
    return this.http
      .get<IStudentLocation[]>(`${this.config.serviceUrls.simulationManagement}locations/${this.course.productCode}/${this.course.courseCode}`);
  }

  recalculateCourseProductScores(simulationInstanceIds: string[]): Observable<string[]> {

    // remove any empty strings
    simulationInstanceIds.reduce(si => { if (si?.length > 0) { return si; } });
    return this.http
      .put<string[]>(`${this.config.serviceUrls.scoringManagement}/${this.course.productCode}/${this.course.courseCode}/rescore`,
        simulationInstanceIds);
  }

  public getCourseProductInformationFromTemplates(courseName: string, simTemplate: ICourseProductTemplate, resultsTemplate: ICourseProductTemplate): ICourseProductInformation {

    if (!simTemplate || !resultsTemplate) {
      // Not ready yet
      return;
    }

    return {
      courseCode: simTemplate.courseCode,
      productCode: simTemplate.productCode,
      courseName,
      starts: null,
      modules: this.getModuleInformationFromTemplates(simTemplate, resultsTemplate)
    };

  }

  // Get the Module/Round Summary Information
  // Used as table headers and settings etc..
  private getModuleInformationFromTemplates(simTemplate: ICourseProductTemplate, resultsTemplate: ICourseProductTemplate): IModuleResultInformation[] {

    // Store the scorable Modules from the resultsTemplate
    const scorableModuleIds = resultsTemplate.modules.map(m => m.id);

    // Populate the Results Headers (used in the table)
    const result: IModuleResultInformation[] = [];

    let i = 1;

    let roundHeader = 'Round';

    // Check if Edify app and change from round to Chapter
    if (resultsTemplate.productCode.includes(this.edifyPrefix) || resultsTemplate.productCode.includes(this.legacyEdifyPrefix)) {
      roundHeader = 'Chapter';
      this.isEdify = true;
    }

    // Check if product is the certification exam(s) and change header from round to Exam
    if (resultsTemplate.productCode.includes(this.certPrefix)) {
      roundHeader = 'Exam';
      this.isCert = true;
    }

    simTemplate.modules.forEach(templateModule => {

      // Check if this is Disabled, and move on if it is...
      const templateProperties = templateModule.properties;

      if (templateProperties?.disableRound) {
        // Round is disabled, ignore from results ad other management...
        return;
      }

      if ((this.isCert || this.isEdify) && templateProperties?.excludeFromResults) {
        // The following check is to accommodate old CERT courses
        if (!this.isCert) {
          return;
        }
        else if (this.isCert && !templateProperties.timeLimit) {
          return;
        }
      }

      // Get the Results Template
      const resultTemplateModule = resultsTemplate.modules.find(rm => rm.id === templateModule.id);
      const resultsProperties = resultTemplateModule?.properties;

      const firstPageId = templateModule.pages[0]?.id || '';

      const moduleInformation: IModuleResultInformation = {
        moduleId: templateModule.id,
        title: templateModule.title,
        firstPageIdOfModule: firstPageId,
        isScored: true,
        starts: null,
        ends: null,
        hasEnded: false,
        possiblePoints: 0,
        tableHeaderDisplay: templateModule.properties?.isCoursewareExam ? templateModule.displayName : !this.isCert ? `${roundHeader} ${i}` : roundHeader,
        simElements: null,
        weights: null,
        isIntroduction: false,
        isConclusion: false,
        questionBankIds: this.findQuestionBankIdsByModuleId(templateModule)
      };

      if (templateProperties) {
        moduleInformation.starts = templateProperties.startDate;
        moduleInformation.ends = templateProperties.endDate;
        moduleInformation.hasEnded = moduleInformation.ends && moduleInformation.ends < new Date();

        moduleInformation.isScored = templateProperties.isScored ?? true;
      }

      // Set the Elements for Use the Results Modal
      moduleInformation.simElements = { elements: [], cacheKey: Math.random().toString() };

      moduleInformation.possiblePoints = 0;

      if (resultsProperties) {

        if (moduleInformation.isScored) {
          // Set the possible points from the template, or default to 0
          // Set to 0 if excluded From Results
          const defaultPoints = 0;
          moduleInformation.possiblePoints = resultsProperties.possiblePoints ?? defaultPoints;
        }

        // Set if it is the Intro or Conclusion rounds...
        moduleInformation.isIntroduction = !!resultsProperties.isIntroduction;
        moduleInformation.isConclusion = !!resultsProperties.isConclusion;

        // Set the scoring weights for this module
        moduleInformation.weights = resultsProperties.weights;

      }

      if (!moduleInformation.isIntroduction && !moduleInformation.isConclusion) {
        // Only increment the round number for non-intro and conclusion
        i++;
      }

      if (moduleInformation.isConclusion) {
        moduleInformation.tableHeaderDisplay = 'Conclusion';
      }

      if (moduleInformation.isIntroduction) {
        moduleInformation.tableHeaderDisplay = 'Introduction';
      }

      if ((resultTemplateModule?.pages || []).length > 0) {
        const page = resultTemplateModule.pages[0];
        moduleInformation.simElements.elements = page.elements || [];
      }

      moduleInformation.isScored = scorableModuleIds.includes(templateModule.id);

      result.push(moduleInformation);
    });

    // TODO, Figure out what this is for....
    /*
        const iterationsByModuleObj = {};
        // Initialize iterationsByModule, which maps iteration ids to module ids
        if (!this.courseProductTemplate || !this.courseProductTemplate.modules) {
          return;
        }

        for (const mod of this.courseProductTemplate.modules) {
          if (!mod.pages) {
            continue;
          }
          for (const page of mod.pages) {
            if (!page.iterations) {
              continue;
            }
            for (const iteration of page.iterations) {
              if (iteration && iteration.id) {
                iterationsByModuleObj[iteration.id] = mod.id;
              }
            }
          }
        }
        this.iterationsByModule = iterationsByModuleObj; */

    return result;
  }

  private findQuestionBankIdsByModuleId(module: ICourseProductTemplateModule): string[] {
    // Finds all question banks in this module
    const questionBankIds = [];
    if (module?.pages.length > 0) {
      module.pages.forEach((p) => {
        if (p && p.elements && p.elements.length > 0) {
          const questionBankElements = p.elements.filter(e => e.type === 'question-bank');
          if (questionBankElements.length > 0) {
            questionBankElements.forEach((e) => {
              if (e && e.config && e.config.questionBankId) {
                questionBankIds.push(e.config.questionBankId);
              }
            });
          }
        }
      });
    }
    return questionBankIds;
  }

  // Returns a label for use on the scores table and other places it needs to change
  public getScoreHeaderLabel(productCode: string) {
    if (productCode.indexOf(this.edifyPrefix) >= 0 || productCode.indexOf(this.legacyEdifyPrefix) >= 0) {
      return 'Chapter';
    }

    if (productCode.indexOf(this.certPrefix) >= 0) {
      return 'Exam';
    }

    return 'Round';
  }

  // Manipulates Student Scores Data
  public buildStudentScoreSummaries(enrolledStudents: IStudent[], studentScoreSummaries: IScoresSummary[], studentLocations: IStudentLocation[], moduleInformation: IModuleResultInformation[], loggedInUser: IUser): IStudentScoreSummary[] {

    if (!studentScoreSummaries ||
      !enrolledStudents ||
      !moduleInformation) {
      // Wait until everything needed is loaded
      return;
    }

    // We can handle not having student locations
    if (!studentLocations) {
      studentLocations = [];
    }

    // Create the Student List to loop over that includes both enrolled and unenrolled students
    const studentList = this.createStudentList(enrolledStudents, studentScoreSummaries, loggedInUser);

    // Loop over the enrollments and create a Student Data Array
    return studentList.map(student => {

      // Get the summary for this student
      const studentScoresSummary = studentScoreSummaries.find(
        summary => summary.studentIdentifier.toLowerCase() === student.identifier.toLowerCase()
      );

      // Get the Students Current Location
      const studentLocation = studentLocations.find(sl => sl.studentIdentifier === student.identifier);

      const studentModuleScores = this.buildStudentRoundScores(studentLocation, studentScoresSummary, moduleInformation);

      // TODO confirm this is needed
      const roundsDictionary = studentModuleScores.reduce((acc, r) => { acc[r.id] = r; return acc; }, {});

      const [totalPointsPossible, totalPointsEarned] = this.getTotalPoints(studentModuleScores);

      let totalPercent = 0;
      if (totalPointsPossible > 0) {
        // Get Total Percentage
        totalPercent = totalPointsEarned / totalPointsPossible;
      }

      // Return the final IStudentScoreSummary
      return {
        firstName: student.firstName,
        lastName: student.lastName,
        displayName: `${student.lastName}, ${student.firstName}`,
        identifier: student.identifier,
        isEnrolled: student.isEnrolled,
        simulationInstanceId: studentScoresSummary?.simulationInstanceId,
        totalPercent,
        totalPointsPossible,
        totalPointsEarned,
        moduleScores: studentModuleScores,
        roundsDictionary,
        lastScoredRound: this.getLastScoredRound(studentModuleScores)
      };

    });

  }

  private createStudentList(enrolledStudents: IStudent[], studentScoreSummaries: IScoresSummary[], loggedInUser: IUser): { firstName: string, lastName: string, identifier: string, isEnrolled: boolean; }[] {

    let results: { firstName: string, lastName: string, identifier: string, isEnrolled: boolean; }[] = [];

    // Loop Enrollments and create the Enrolled Students
    results = enrolledStudents.map(student => {
      return {
        firstName: student.firstName,
        lastName: student.lastName,
        identifier: student.identifier,
        isEnrolled: true
      };
    });

    // Find any unenrolled Results
    studentScoreSummaries.forEach(summary => {
      if (!results.some(s => s.identifier === summary.studentIdentifier)) {
        // Need to add the unenrolled student
        results.push({
          firstName: summary.studentIdentifier === loggedInUser.email ? loggedInUser.firstName : 'User',
          lastName: summary.studentIdentifier === loggedInUser.email ? loggedInUser.lastName : 'Unenrolled',
          identifier: summary.studentIdentifier,
          isEnrolled: false
        });
      }
    });

    return results;
  }

  private buildStudentRoundScores(studentLocation: IStudentLocation, studentScoresSummary: IScoresSummary, moduleInformation: IModuleResultInformation[]): ISummaryModuleScore[] {

    // For Each Module/Round in the moduleInformation, add the students scores, or set it to null
    return moduleInformation.map(r => {
      // Populate the Rounds
      const moduleScore: ISummaryModuleScore = this.getNullModuleScore(r.moduleId, r.tableHeaderDisplay);

      moduleScore.pointsPossible = r.possiblePoints;
      moduleScore.isCurrentLocation = (studentLocation?.location?.moduleId === r.moduleId);

      moduleScore.isIntroduction = r.isIntroduction;
      moduleScore.isConclusion = r.isConclusion;

      moduleScore.isScored = r.isScored;

      moduleScore.hasResults = false;

      // For Scored modules/rounds, set it from the ResultsSummary from the API
      if (studentScoresSummary) {

        if (moduleScore.isIntroduction) {
          // Fuzzy logic here..  If they have anything past the intro, they have completed the intro
          // Or, if the location is not the intro, they have completed the intro
          if (!moduleScore.isCurrentLocation || studentLocation?.location?.moduleId?.length > 0) {
            moduleScore.isComplete = true;
          }
        } else {

          const summaryScore = studentScoresSummary.modules.find(m => m.id === r.moduleId);

          if (summaryScore) {
            moduleScore.isComplete = true;
            moduleScore.hasResults = true;

            moduleScore.score = summaryScore.percentage;
            moduleScore.percentage = summaryScore.percentage;
            moduleScore.points = Math.round(moduleScore.score * moduleScore.pointsPossible);
          }
        }

      }

      return moduleScore;
    });

  }

  public getLastScoredRound(rounds: ISummaryModuleScore[]): ISummaryModuleScore {
    if (rounds && rounds.length > 0) {
      const scoredRounds = rounds.filter(r => r.hasResults || r.isIntroduction || r.isComplete);
      return scoredRounds[scoredRounds.length - 1];
    } else {
      return null;
    }
  }

  private getTotalPoints(scoredStudentModules: ISummaryModuleScore[]): number[] {

    // Get the Total Points from the Students Total Points possible
    // This only includes rounds scored with hasResults and isIncluded...
    const totalPointsPossible = scoredStudentModules.reduce(
      (accumulatedPoints: number, studentModuleScore: ISummaryModuleScore) => {
        if (studentModuleScore.isScored && studentModuleScore.hasResults) {
          return accumulatedPoints + studentModuleScore.pointsPossible;
        } else {
          return accumulatedPoints;
        }
      }, 0);

    const totalPointsEarned = scoredStudentModules.reduce((accumulatedPoints: number, round: ISummaryModuleScore) => accumulatedPoints + round.points, 0);

    // Return them
    return [totalPointsPossible, totalPointsEarned];
  }

  private getNullModuleScore(id: string, title: string): ISummaryModuleScore {
    return {
      id,
      name: title,
      score: null,
      percentage: null,
      points: null,
      pointsPossible: null,
      hasResults: false,
      isComplete: false,
      isCurrentLocation: false,
      isScored: false,
      isConclusion: false,
      isIntroduction: false
    };
  }

}
