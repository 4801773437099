import { createSelector } from '@ngrx/store';

export const rootScoringFeature = state => state.scores;
export const resultsTemplateFeature = state => state.scores.template;
export const summariesFeature = state => state.scores.studentSummaryPages;

export const selectStudentSummaries = createSelector(
    summariesFeature,
    state => state
);
