import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { IModule } from '../../../models/module.model';
import { ICourseSettings } from '../../../models/simulation/simulation-settings.model';

@Component({
  selector: 'app-simulation-modules',
  templateUrl: './simulation-modules.component.html',
  styleUrls: ['./simulation-modules.component.scss']
})
export class SimulationModulesComponent implements OnInit, OnChanges {
  @Input() courseSettings: ICourseSettings;
  @Input() modules: IModule[];

  // Support two-way binding on modules
  @Output() moduleChange = new EventEmitter<IModule>();
  // Let parent component know when modules change
  @Output() settingsChanged = new EventEmitter<boolean>();

  hasAtLeastOneEndDate: boolean;
  canModifyRoundOrder = true;

  workingModules: IModule[] = [];

  ngOnInit() {
    this.setSettingsFromTemplate();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.modules) {
      this.workingModules = JSON.parse(JSON.stringify(this.modules));
    }

    this.setSettingsFromTemplate();
  }

  updateStartDate(date: Date, module: IModule) {
    const moduleCopy = JSON.parse(JSON.stringify(module));
    // If there is a date, remove the time, otherwise set the date to null.
    if (date) {
      moduleCopy.settings.startDate = this.removeTime(date);
    } else {
      // Set it to null here. If we were to pass null to `this.removeTime()` we
      // would get a date for December 31, 1979. We don't want that. - CAM
      moduleCopy.settings.startDate = null;
    }

    this.moduleChange.emit(moduleCopy);
  }

  updateEndDate(date: Date, module: IModule) {
    const moduleCopy = JSON.parse(JSON.stringify(module));
    // If there is a date, remove the time, otherwise set the date to null.
    if (date) {
      moduleCopy.settings.endDate = this.removeTime(date);
    } else {
      // Set it to null here. If we were to pass null to `this.removeTime()` we
      // would get a date for December 31, 1979. We don't want that. - CAM
      moduleCopy.settings.endDate = null;
    }

    this.moduleChange.emit(moduleCopy);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.modules, event.previousIndex, event.currentIndex);

  }

  checkForChanges(module: IModule) {
    this.moduleChange.emit(module);
  }

  private setSettingsFromTemplate() {
    const singleRoundWithEndDate = this.modules.find(m => !!m.settings.allowEndDate);

    // this is used for styling purposes
    this.hasAtLeastOneEndDate = (singleRoundWithEndDate && this.courseSettings.enableModuleEndDates);

  }

  private removeTime(date: string | Date): Date {
    date = date instanceof Date ? date : new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  trackById(module: IModule) {
    return module.id;
  }
}
