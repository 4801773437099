import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ScoresService } from '../../services/scores/scores.service';
import { loadStudentsScores, loadStudentModuleScores, loadStudentSummary, setStudentModuleScores, setStudentSummary, setAllScores } from './scores.actions';

@Injectable()
export class InstructorPortalScoresEffects {

  constructor(
    private actions$: Actions,
    private scoresService: ScoresService
  ) { }

  loadStudentSummary$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudentSummary),
    mergeMap(() =>
      this.scoresService.getStudentWorkSummary()
        .pipe(
          map(summary => setStudentSummary({ summary })),
          catchError(() => EMPTY)
        )
    )
  ));

  loadStudentModuleScores$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudentModuleScores),
    mergeMap(({ studentIdentifier, moduleId, iterationsByModule }) =>
      this.scoresService.getStudentScores(studentIdentifier)
        .pipe(
          map(mod => setStudentModuleScores({ scores: mod.scores, moduleId, iterationsByModule })),
          catchError(() => EMPTY)
        )
    )
  ));

  loadStudentScores$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudentsScores),
    mergeMap(() =>
      this.scoresService.getStudentsScores()
        .pipe(
          map(allScores => setAllScores({ allScores })),
          catchError(() => EMPTY)
        )
    )
  ));

}
