import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { resetSim, resetModuleScore, resetScoresByLocationIds, resetQuestionInstance, resetLocation, scoreModuleForStudent } from './simulation.actions';
import { SimManagementService } from '../../services/sim-management/sim-management-service';
import { loadStudentSummary } from '../scores/scores.actions';

@Injectable()
export class SimulationEffects {

  constructor(
    private actions$: Actions,
    private simManagementService: SimManagementService,
  ) {
  }

  resetSim$ = createEffect(() => this.actions$.pipe(
    ofType(resetSim),
    mergeMap(({ studentIdentifier, moduleId }) =>
      this.simManagementService.resetSim(studentIdentifier, moduleId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  resetModuleScore$ = createEffect(() => this.actions$.pipe(
    ofType(resetModuleScore),
    mergeMap(({ simulationInstanceId, moduleId }) =>
      this.simManagementService.resetModuleScore(simulationInstanceId, moduleId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  resetScoresByLocationIds$ = createEffect(() => this.actions$.pipe(
    ofType(resetScoresByLocationIds),
    mergeMap(({ simulationInstanceId, locationIds }) =>
      this.simManagementService.resetScoresByLocationIds(simulationInstanceId, locationIds)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  resetQuestionInstance$ = createEffect(() => this.actions$.pipe(
    ofType(resetQuestionInstance),
    mergeMap(({ studentIdentifier, questionBankId, userInstanceCode }) =>
      this.simManagementService.resetQuestionInstance(studentIdentifier, questionBankId, userInstanceCode)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  resetLocation$ = createEffect(() => this.actions$.pipe(
    ofType(resetLocation),
    mergeMap(({ studentIdentifier, moduleId, pageId, instanceId }) =>
      this.simManagementService.resetLocation(studentIdentifier, moduleId, pageId, instanceId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  scoreModuleForStudent$ = createEffect(() => this.actions$.pipe(
    ofType(scoreModuleForStudent),
    mergeMap(({ studentIdentifier, moduleId }) =>
      this.simManagementService.scoreModuleForStudent(studentIdentifier, moduleId)
        .pipe(
          map(() => loadStudentSummary()),
          catchError(() => EMPTY)
        )
    )
  ));
}
