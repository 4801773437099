
// This should mirror the "properties" from a CourseProductTemplate
// Since Properties is untyped, this kind of controls it...
export interface ICourseSettings {
  startDate?: Date;
  enableModuleEndDates?: boolean;
  disableReorderModules?: boolean;
  scoreOnDueDateEnabled?: boolean;
  showQuestionBankAnswers?: boolean;
  allowSubmissionsAfterDueTime?: boolean;
  allowPeerReviewFeedbackDueDates?: boolean;
  allowEarlyDistribution?: boolean;
  enableQuizzes?: boolean;
  showResultsSummary?: boolean;
}

export interface IModuleSetting {
  moduleId: string;
  settings: IModuleSettings;
}

// This should mirror the "properties" from a Module
// Since Properties is untyped, this kind of controls it...
export interface IModuleSettings {
  allowDisableEnable?: boolean;
  disableRound?: boolean;
  enabled: boolean;
  startDate: Date;
  allowEndDate?: boolean;
  endDate?: Date;
}

export interface IPageSetting {
  moduleId?: string;
  id: string;
  settings: IPageSettings;
}

// This should mirror the "properties" from a Module
// Since Properties is untyped, this kind of controls it...
export interface IPageSettings {
  allowDisableEnable?: boolean;
  enabled?: boolean;
  startDate?: Date;
  allowEndDate?: boolean;
  endDate?: Date;
}

export interface IDueDateTimersSetting {
  moduleId: string;
  elementId: string;
  dueDate: Date;
  timerAction: TimerAction;
}

export interface IDueDateTimers {
  scoreOnDueDate: boolean;
  dueDateTimers: IDueDateTimersSetting[];
  allowSubmissionsAfterDueTime?: boolean;
}

export enum TimerAction {
  Stop = 'Stop',
  Start = 'Start'
}

export interface IPeerReviewTableRow {
  id: string;
  name: string;
  round: string;
  endDate: Date;
  moduleId: string;
  pageId: string;
  error: boolean;
}
