import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { of } from 'rxjs';
import { CourseProductTemplateService } from '../../services/course-product-template/course-product-template.service';
import { loadVersionInformation, loadVersionInformationFailed, setVersionInformation, upgradeTemplate, upgradeTemplateFailed, upgradeTemplateSucceeded } from './templateVersions.actions';

@Injectable()
export class VersionEffects {

  @Effect()
  loadVersions$ = this.actions$.pipe(
    ofType(loadVersionInformation),
    mergeMap(({ productCode }) =>
      this.courseProductTemplateService.getVersionInformation(productCode)
        .pipe(
          map(versions => setVersionInformation({ versions })),
          catchError((err) => {
            const errorMessage = err.error;
            return of(loadVersionInformationFailed({ errorMessage: `Failed to get Template Version Information due to error: ${errorMessage}.` }));
          })
        )
    )
  );

  @Effect()
  upgradeTemplate = this.actions$.pipe(
    ofType(upgradeTemplate),
    mergeMap(({ productCode, versions }) =>
      this.courseProductTemplateService.upgradeTemplate(productCode)
        .pipe(
          map(() => upgradeTemplateSucceeded()),
          catchError((err) => {
            let errorMessage = '';

            if (typeof err.error === 'string') {
              errorMessage = err.error;
            } else {
              errorMessage = JSON.stringify(err.error);
            }
            return of(upgradeTemplateFailed({ errorMessage: `Failed to Upgrade the Template Version Information due to error: ${errorMessage}.`, versions }));
          })
        )
    )
  );

  constructor(
    private actions$: Actions,
    private courseProductTemplateService: CourseProductTemplateService
  ) {
  }
}
