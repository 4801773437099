import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { SimInstanceService } from '../../services/simInstance/simInstance.service';
import { getStudentSimInstance, updateStudentSimInstanceState } from './core.actions';
import { EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { courseProductInstance, setSimulationInstance } from '@stukent/mimic-core';

@Injectable()
export class InstructorPortalCoreEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private simInstanceService: SimInstanceService
  ) { }

  private currentIdentifier: string;

  getStudentInstance$ = createEffect(() => this.actions$.pipe(
    ofType(getStudentSimInstance),
    withLatestFrom(this.store.select(courseProductInstance)),
    mergeMap(([{ studentIdentifier, productCode }, instance]) => {

      if (studentIdentifier === this.currentIdentifier) {
        return [setSimulationInstance({ instance })];
      } else {

        this.currentIdentifier = studentIdentifier;

        return this.simInstanceService.getStudentInstance(studentIdentifier, productCode)
          .pipe(
            map(simInstance => setSimulationInstance({ instance: simInstance })),
            catchError(() => EMPTY)
          );

      }

    }
    )
  ));

  updateStudentInstanceState$ = createEffect(() => this.actions$.pipe(
    ofType(updateStudentSimInstanceState),
    mergeMap((newState) =>
      this.simInstanceService.updateStudentSimInstanceState(newState.state, newState.identifier)
        .pipe(
          catchError((err) => {
            console.error('ERROR', err);
            return EMPTY;
          })
        )
    )), { dispatch: false });
}
