import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStudent } from '../../models/student.model';
import { Store } from '@ngrx/store';
import { ICourseState } from '../../reducers/course/course.reducer';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { map } from 'rxjs/operators';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root',
})
export class ClassListService {
  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);

  }

  getEnrollments(): Observable<IStudent[]> {
    return this.http
      .get<{ users: IStudent[], courseCode: string, courseId: string }>
      (`${this.config.serviceUrls.courses}/enrollments/${this.course.courseCode}`)
      .pipe(
        map(students => students.users.map(u => {
          return { ...u, displayName: `${u.lastName}, ${u.firstName}` };
        }))
      );
  }
  removeStudentEnrollment(studentIdentifier: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        studentIdentifier,
      },
    };

    return this.http
      .delete<{ users: IStudent[], courseCode: string }>
      (`${this.config.serviceUrls.enrollmentManagement}/${this.course.courseCode}/student`,
        options);
  }
}
