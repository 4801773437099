import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { loadCourse, setCourse, loadMyCourses, setMyCourses, setCourseFailed } from './course.actions';
import { CourseService } from '../../services/course/course.service';
import { of } from 'rxjs';

@Injectable()
export class CourseEffects {

  constructor(
    private actions$: Actions,
    private courseService: CourseService
  ) {
  }

  @Effect()
  loadCourse$ = this.actions$.pipe(
    ofType(loadCourse),
    mergeMap(() =>
      this.courseService.get()
        .pipe(
          map(course => setCourse({ course })),
          catchError((err) => {
            const errorMessage = err.error;
            return of(setCourseFailed({ errorMessage: `Failed to get the Course due to error: ${errorMessage}.` }));
          })
        )
    )
  );

  @Effect()
  loadMyCourses = this.actions$.pipe(
    ofType(loadMyCourses),
    mergeMap(() =>
      this.courseService.getMyCourses()
        .pipe(
          map(courses => setMyCourses({ courses })),
          catchError(() => EMPTY)
        )
    )
  );
}
