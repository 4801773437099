import { createAction, props } from '@ngrx/store';
import { ITemplateVersion } from '../../models/tempate-versions.model';

const prefix = '[TemplateVersions]';
export const loadVersionInformation = createAction(`${prefix} Get Version Information`, props<{ productCode: string; }>());
export const loadVersionInformationFailed = createAction(`${prefix} Failed to Get Version Information`, props<{ errorMessage: string; }>());
export const setVersionInformation = createAction(`${prefix} Set Version Information`, props<{ versions: ITemplateVersion[]; }>());

export const upgradeTemplate = createAction(`${prefix} Upgrade Product Template`, props<{ productCode: string, versions: ITemplateVersion[]; }>());
export const upgradeTemplateSucceeded = createAction(`${prefix} Upgraded Product Template`);
export const upgradeTemplateFailed = createAction(`${prefix} Failed to Upgrade Product Template`, props<{ errorMessage: string, versions: ITemplateVersion[]; }>());
