import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(
    private http: HttpClient
  ) {
  }

  get(courseCode: string, productCode: string): Observable<IInstructorPortalConfiguration> {
    return this.http
      .get<IInstructorPortalConfiguration>(`https://api.stukent.com/config/instructor/${environment.env}/${courseCode}/${productCode}`, {
        headers: {
          'Ocp-Apim-Subscription-Key': environment.configKey
        }
      });
  }
}
