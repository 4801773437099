import { ICourseState } from '../../reducers/course/course.reducer';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ISimulationInstance } from '@stukent/mimic-core';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class SimInstanceService {
  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  getStudentInstance(studentIdentifier: string, productCode: string): Observable<ISimulationInstance> {
    return this.http.get<ISimulationInstance>(`${this.config.serviceUrls.simulationInstanceManagement}/${productCode}/${this.course.courseCode}/${studentIdentifier}`);
  }

  updateStudentSimInstanceState(newState: any, identifier: string) {
    const headers = new HttpHeaders().set('x-override-user-identifier', identifier);
    return this.http.put<any>(`https://api.stukent.com/simulationinstance/v1/course/sim/${this.course.productCode}/${this.course.courseCode}/state/instance`, newState, { headers });
  }
}
