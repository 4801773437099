import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../../reducers/selectors';
import { IInstructorPortalConfiguration } from '../../../models/app-config.model';
import { IStudent } from '../../../models/student.model';
import { ICourseState } from '../../../reducers/course/course.reducer';
import { ILinkedCourseData } from 'src/app/models/lms-integration.models';

@Injectable({
  providedIn: 'root'
})
export class LmsIntegrationService {

  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  getCourse(): Observable<ILinkedCourseData> {
    return this.http.get<ILinkedCourseData>(`${this.config.serviceUrls.identityLmsUrl}/course/${this.course.courseCode}`);
  }

  getEnrollments(): Observable<IStudent[]> {
    return this.http.get<{ users: IStudent[], courseCode: string, courseId: string; }>(`${this.config.serviceUrls.identityLmsUrl}/course/${this.course.courseCode}/enrollments/`)
      .pipe(
        map(students => students.users)
      );
  }
}
