import { Injectable } from '@angular/core';
import { ICourseScores, IStudentScoreSummary, ISummaryModuleScore } from 'src/app/models/simulation/scores/simulation-results';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  // Export Setup
  private csvGenerator: any; // d3-csv library

  constructor() {
    // Set the CSV Generator to the Window d3 library
    this.csvGenerator = (window as any).d3;
  }

  export(courseScores: ICourseScores): void {
    const csvObj = this.createCSV(courseScores);

    this.downloadCSV(csvObj, courseScores);
  }

  createCSV(courseScores: ICourseScores) {
    const csvObj = courseScores.courseProductResults.filter(s => s.isEnrolled || courseScores.unEnrolledStudentsAreShown).map((student: IStudentScoreSummary) => {

      const firstColumns = {
        // Don't show "Unenrolled, User" in the csv
        FirstName: student.firstName.toLocaleLowerCase() !== 'user' ? student.firstName : '',
        LastName: student.lastName.toLocaleLowerCase() !== 'unenrolled' ? student.lastName : '',
        Email: student.identifier,
        Total: this.getExportTotal(student.totalPercent, student.totalPointsEarned, student.totalPointsPossible, courseScores.scoreType),
      };

      return student.moduleScores.reduce((acc: any, round: ISummaryModuleScore) => {
        if (!(round.isIntroduction || round.isConclusion)) {
          if (round.pointsPossible > 0) {
            acc[round.name] = this.getExportScore(round.points, round.pointsPossible, courseScores.scoreType);
          } else if (round.hasResults) {
            acc[round.name] = 'Pass';
          } else {
            acc[round.name] = '';
          }
        }
        return acc;
      }, firstColumns);
    });

    return csvObj;
  }

  private downloadCSV(csvObj: any, courseScores: ICourseScores, csvName?: string) {

    if (courseScores.allScores.length === 0) {
      alert('There are no scores to export.');
      return;
    }

    let fileName = `StudentChapterScores(${courseScores.allScores[0].context.courseCode}).csv`;
    if (csvName) {
      fileName = csvName;
    }

    const csv = this.csvGenerator.csvFormat(csvObj);

    // Auto download by clicking a link injected into the DOM
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('download', fileName);
    a.href = `data:application/octet-stream,${encodeURI(csv)}`;
    document.body.appendChild(a);
    a.click();
    a.parentElement.removeChild(a);
  }

  exportAllScorableItems(courseScores: ICourseScores, studentIdentifier?: any): void {
    if (courseScores.allScores?.length === 0) {
      alert('There are no scores to export.');
      return;
    }

    let csvName = `StudentSubmissionsScores(${courseScores.allScores[0].context.courseCode}).csv`;

    let studentScores;
    let allScoresForStudent;

    if (studentIdentifier) {
      if (studentIdentifier.lastName.toLowerCase() === 'unenrolled') {
        csvName = `StudentSubmissionsScores(${studentIdentifier.identifier}-${courseScores.allScores[0].context.courseCode}).csv`;
      } else {
        csvName = `StudentSubmissionsScores(${studentIdentifier.displayName}-${courseScores.allScores[0].context.courseCode}).csv`;
      }

      allScoresForStudent = courseScores.allScores.filter(as => as.context.studentIdentifier === studentIdentifier.identifier);

      studentScores = courseScores.courseProductResults
        .filter(s => s.isEnrolled || courseScores.unEnrolledStudentsAreShown)
        .filter(r => r.identifier === studentIdentifier.identifier);

    } else {
      studentScores = courseScores.courseProductResults
        .filter(s => s.isEnrolled || courseScores.unEnrolledStudentsAreShown);
    }

    const csvObj = this.createScorableItemsCSV(studentScores, courseScores, allScoresForStudent);

    this.downloadCSV(csvObj, courseScores, csvName);
  }

  createScorableItemsCSV(studentScores: any, courseScores: ICourseScores, allScoresForStudent: any) {
    const csvObj = [];

    const scorableItems = courseScores.scorableItems.scorableItemsConfiguration.filter(c => c.scorableItemType === 'QuestionBank');

    studentScores.forEach(student => {
      scorableItems.forEach(item => {
        let row = {
          // Don't show "Unenrolled, User" in the csv
          FirstName: student.firstName.toLocaleLowerCase() !== 'user' ? student.firstName : '',
          LastName: student.lastName.toLocaleLowerCase() !== 'unenrolled' ? student.lastName : '',
          Email: student.identifier,
          Title: item.scorableItemName,
          DateDue: null,
          DateSubmitted: null,
          Score: '-- / --',
        };

        row = this.setQuestionBankConfigOnRow(row, courseScores.scorableItems.scorableItems.questionBank, item);
        row = this.setScoresOnRow(row, courseScores, student, item);
        csvObj.push(row);
      });

    });

    return csvObj;
  }

  private setQuestionBankConfigOnRow(row: any, questionBank, item): any {
    row.DateDue = ' - ';
    if (item.scorableItemType !== 'QuestionBank') { return row; }

    const questionBankId = item?.configuration?.questionBankId;

    const qbConfig = questionBank.find(c => c.questionBankId === questionBankId);
    if (!qbConfig) { return row; }

    row.DateDue = qbConfig.settings.dueDate;

    return row;
  }

  private setScoresOnRow(row: any, courseScores: ICourseScores, student: any, item): any {
    row.DateSubmitted = ' - ';
    row.Score = '-- / --';

    if (!courseScores.courseProductResults) {
      return row;
    }

    const scorerId = item.scorerId;

    const score = courseScores.allScores.filter(as => as.context.studentIdentifier === student.identifier).find(s => s.scorerId === scorerId);

    if (!score) { return row; }

    if (score.createdDate) {
      row.DateSubmitted = new Date(score.createdDate);
    }
    if (courseScores.scoreType === 'percent') {
      row.Score = `${score.scorePercent * 100}%`;
      return row;
    }

    const points = score.scorePercent * score.possiblePoints;
    row.Score = `${Math.round(points)} / ${score.possiblePoints}`;
    return row;
  }
  private getExportTotal(totalPercent: number, earned: number, possible: number, scoreType: string): string {
    if (this.isNumeric(totalPercent) && this.isNumeric(earned) && this.isNumeric(possible)) {

      if (scoreType === 'percent') {
        return `${(totalPercent * 100).toFixed(2)} %`;
      } else {
        return `${earned} / ${possible}`;
      }
    } else {
      // Would be NAN
      return '';
    }
  }

  private getExportScore(earned: number, possible: number, scoreType: string): string {
    if (this.isNumeric(earned) && this.isNumeric(possible)) {
      if (scoreType === 'percent') {
        return `${Math.round(earned / possible * 100)} %`;
      } else {
        return `${earned} / ${possible}`;
      }
    } else {
      // Would be NAN
      return '';
    }
  }

  private isNumeric(val: any): boolean {
    return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
  }
}
