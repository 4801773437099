import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadCoreConfig } from '@stukent/mimic-core';
import { currentUser } from '@stukent/user';
import { loadInstructorPortalConfig } from './reducers/config/config.actions';
import { setCourseCode, setProductCode } from './reducers/course/course.actions';
import { selectInstructorPortalConfiguration } from './reducers/selectors';

@Component({
  selector: 'app-instructor-portal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class InstrcutorPortalComponent implements OnInit {

  currentSaying = 'Please wait while we make sure we know who you are...';

  readyToGo = false;
  private profileLoaded = false;
  private configLoaded = false;

  reloadUrl = window.location;

  // Create a load timeout...
  private loadTimeout = 3000;

  timedOut = false;

  constructor(
    private store: Store
  ) {
  }

  ngOnInit(): void {
    // This is purposefully done at the constructor
    // In case any child components need the Course or Product Code
    const params = window.location.pathname.split('/');
    const courseCode = params[1] ?? '';
    const productCode = params[2] ?? '';

    this.store.dispatch(loadInstructorPortalConfig({ courseCode, productCode }));
    this.store.dispatch(loadCoreConfig({
      platform: 'mimic',
      courseCode,
      area: 'na',
      topic: productCode
    }));

    this.store.dispatch(setCourseCode({ courseCode }));
    this.store.dispatch(setProductCode({ productCode }));

    this.store.select(currentUser).subscribe(user => {
      if (!user.isLoading) {
        this.profileLoaded = true;
        this.isRreadyToGo();
      }
    });

    this.store.select(selectInstructorPortalConfiguration).subscribe(configuration => {
      if (configuration?.subscriptionKey?.length > 0) {
        this.configLoaded = true;
        this.isRreadyToGo();
      }
    });

    this.timeoutLoading();

  }

  private isRreadyToGo(): void {

    if (this.profileLoaded) {
      this.currentSaying = 'Making sure we are safe and secure, you don\'t mind I\'m sure...';
    }

    if (this.configLoaded && this.profileLoaded) { this.readyToGo = true; }

  }

  private timeoutLoading() {
    setTimeout(() => {
      if (!this.readyToGo) {
        this.timedOut = true;
      }
    }, this.loadTimeout);

  }
}
