import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadEnrollments } from '../../reducers/enrollment/enrollment.actions';
import { loadCourse } from '../../reducers/course/course.actions';
import { loadCourseProductTemplate, loadResultsTemplate, setOnlyCourseSettings, setProductTemplateSettings } from '../../reducers/template/template.actions';
import { Subscription } from 'rxjs';
import { ElementService } from '@stukent/elements';
import { IInstructorPortalConfiguration, IUrlConfig } from '../../models/app-config.model';
import { IStudent } from '../../models/student.model';
import { ICourseState } from '../../reducers/course/course.reducer';
import { ICourse } from '../../models/course.model';
import { ICourseProductTemplateState } from '../../reducers/template/template.reducer';
import { IProduct } from '../../models/product.model';
import { IEnrollmentState } from '../../reducers/enrollment/enrollment.reducer';
import { ICourseSettings, IModuleSetting, IPageSetting, IPageSettings } from '../../models/simulation/simulation-settings.model';
import { ScoresService } from '../../services/scores/scores.service';
import { IStudentLocation } from '../../models/simulation/location.model';
import { ICourseProductScoresSummary } from '../../models/simulation/scores/student-work-summary.model';
import { loadStudentsScores, loadStudentSummary } from '../../reducers/scores/scores.actions';
import { currentUser, IUser } from '@stukent/user';
import { FeatureArea, FeatureService, LocationService } from '@stukent/feature-toggle';
import { IScorableItemsState, IQuestionBankSettings, IElementSettings } from 'src/app/models/scorable-items/scorable-items-state.model';
import { ICourseProductTemplate, ICourseProductTemplateModule, IScore, MimicCoreConfig, setCourseProductTemplateWithoutInstance } from '@stukent/mimic-core';
import { ICourseProductInformation, IStudentScoreSummary } from '../../models/simulation/scores/simulation-results';
import { IInstructorResourceState, IResourceState } from '../../models/resources/instructor-resource.model';
import { loadInstructorResources } from '../../reducers/instructor-resource/instructor-resource.actions';
import { selectInstructorResources } from '../../reducers/instructor-resource/instructor-resource.selectors';
import { loadQuestionBankSettings, saveQuestionBankSettings } from '../../reducers/scorable-items/scorable-items.actions';
import { selectStudentSummaries } from '../../reducers/scores/scores.selectors';
import {
  selectCurrentCourse,
  selectCurrentCourseEnrollments,
  selectCurrentCourseProductTemplate,
  selectCurrentCourseScorableItems,
  selectInstructorPortalConfiguration
} from '../../reducers/selectors';
import { selectCurrentCourseProductTemplateVersions } from '../../reducers/templateVersions/templateVersions.selectors';
import { selectAllScores, selectScores } from '../../reducers/scores/scores.selector';
import { selectScorableItems } from '../../reducers/scorable-items/scorable-items.selectors';
import { loadLinkedCourse } from 'src/app/reducers/lms-integration/lms-integration.actions';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit, OnDestroy {

  constructor(
    private elementService: ElementService,
    private featuresService: FeatureService,
    private scoresService: ScoresService,
    private locationService: LocationService,
    private store: Store,
    public coreConfig: MimicCoreConfig,
  ) { }

  settingsTitle = 'Settings (loading)';
  settingsFailed = false;

  scoresTitle = 'Student Results (loading)';
  scoresFailed = false;

  enrollmentsTitle = 'Enrollments (loading)';
  enrollmentsFailed = false;

  instructorResourceTitle = 'Resources (loading)';
  instructorResourceFailed = false;

  // need to find a spot for this to change
  rankingTitle = 'Ranking';
  rankingFailed = false;

  private tabShortNames = ['scores', 'enrollments', 'resources', 'settings', 'ranking'];

  course: ICourse;
  courseCode: string;
  productCode: string;

  homeUrl: string;

  loggedInUser: IUser;

  currentProduct: IProduct;
  currentProductThemeColor: string;

  // TODO see if I can get these private
  courseProductTemplate: ICourseProductTemplate;
  productResultsTemplate: ICourseProductTemplate;

  courseProductInformation: ICourseProductInformation;

  private currentProductScoresSummary: ICourseProductScoresSummary;

  studentLocations: IStudentLocation[];

  enrolledStudents: IStudent[];
  questionBankSettings: IQuestionBankSettings[] = [];
  pageSettings: IPageSettings[] = [];
  elementSettings: IElementSettings[] = [];

  instructorResources: IResourceState[];
  // passed to results child components
  courseProductResults: IStudentScoreSummary[];

  private courseProductTemplateProperties: { [key: string]: any; } = { hasSeenCertificationModal: true };

  mimicAppUrls: IUrlConfig[];
  edifyBaseUrl: string;
  timeLimit: number;

  initialTabIndex = 1;
  courseLoaded = false;
  certificationModalVisible = false;

  public instructorConfiguration: IInstructorPortalConfiguration;

  productLaunchUrl: string;

  private subscriptions: Subscription[] = [];

  failedToLoad: boolean;
  failureMessage = 'We were unable to process this request. ';

  showEnrollmentsTab = false;
  showResourcesTab = false;
  showRankingTab = false;
  showSettingsTab = false;
  showResultSummaryToggle = false;
  syncWithLmsCourse = false;

  hasUpdateAvailble = false;
  scorableItems: IScorableItemsState;
  allScores: IScore<any>[];

  ngOnInit(): void {

    // Configuration Subscription
    this.subscriptions.push(this.store.select(selectInstructorPortalConfiguration).subscribe(this.handleConfigChanged.bind(this)));

    // Course Subscription
    this.subscriptions.push(this.store.select(selectCurrentCourse).subscribe(this.handleCourseChanged.bind(this)));

    // Profile Subscription
    this.subscriptions.push(this.store.select(currentUser).subscribe(this.handleProfileChanged.bind(this)));

    // Templates Subscription
    this.subscriptions.push(this.store.select(selectCurrentCourseProductTemplate).subscribe(this.handleTemplatesChanged.bind(this)));

    // Handle Course Enrollments Loaded to change label...
    this.subscriptions.push(this.store.select(selectCurrentCourseEnrollments).subscribe(this.handleStudentEnrollments.bind(this)));

    // Handle Student Score Summaries Loaded
    this.subscriptions.push(this.store.select(selectScores).subscribe(this.handleScoreSummaryDataChanged.bind(this)));

    // Get Scorable Items
    this.subscriptions.push(this.store.select(selectScorableItems).subscribe(this.handleScorableItemsChange.bind(this)));

    // Handle Student Score Summaries Loaded
    this.subscriptions.push(this.store.select(selectStudentSummaries).subscribe(this.handleScoreSummaryDataChanged.bind(this)));

    // Check for version alerts
    this.subscriptions.push(this.store.select(selectCurrentCourseProductTemplateVersions).subscribe(versionState => {
      if (versionState.areLoaded) {
        this.hasUpdateAvailble = versionState.versions?.length > 0;
      }
    }));

    // Handle Scorable Items loaded
    this.subscriptions.push(this.store.select(selectCurrentCourseScorableItems).subscribe(this.handleScorableItems.bind(this)));

    this.store.dispatch(loadStudentsScores({
      simulationInstanceId: null,
      moduleId: null,
      iterationsByModule: null
    }));

    this.store.select(selectAllScores).subscribe(as => {
      this.allScores = as;
    });

  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  private handleScorableItems(settings: IScorableItemsState) {
    this.questionBankSettings = settings.scorableItems?.questionBank || [];
  }

  private handleConfigChanged(config: IInstructorPortalConfiguration): void {
    this.instructorConfiguration = config;
    if (this.instructorConfiguration.isLoaded && this.instructorConfiguration.subscriptionKey.length > 0) {

      this.edifyBaseUrl = config.serviceUrls.edifyBaseUrl;
      // TODO Convert this to use the Service, not a configuration
      this.mimicAppUrls = config.mimicAppUrls;

      this.store.dispatch(loadCourse());

      this.homeUrl = this.locationService.homeUrl;
    }
  }

  private handleCourseChanged(course: ICourseState): void {
    if (!course?.isLoading) {

      this.courseLoaded = true;
      this.course = course.course;

      if (course?.isFaulted && this.coreConfig.isProduction) {
        this.failedToLoad = true;

        this.failureMessage += ' ' + course.errorMessage;

      } else {

        this.failedToLoad = false;
        this.courseCode = course.courseCode;
        this.productCode = course.productCode;

        // Trying find both variations of edify
        this.currentProduct =
          this.course.products.find(p => p.code.toLowerCase() === this.productCode.toLowerCase());

        this.productLaunchUrl = this.getProductUrl(this.courseCode, this.productCode);

        if (this.elementService) {
          this.elementService.setBaseUrl(this.instructorConfiguration.serviceUrls.elements);
          this.elementService.setElementBaseUrl(this.instructorConfiguration.serviceUrls.elementBase);
          this.elementService.init();
        }

        // Get the Enrollments, Template, Results Template
        this.store.dispatch(loadResultsTemplate());
        this.store.dispatch(loadEnrollments());
        this.store.dispatch(loadCourseProductTemplate());
        this.store.dispatch(loadQuestionBankSettings());

        // Load the Student Locations
        // TODO move to a Redux so it is live as students actually make progress (JKW)
        this.loadStudentLocations();

        // Load Lms Integration data
        if (this.syncWithLmsCourse) {
          this.store.dispatch(loadLinkedCourse());
        }
      }

    }
  }

  private handleProfileChanged(user: IUser): void {
    this.loggedInUser = user;
    this.setupFeatures();
    this.setcourseProductResults();

  }

  private handleTemplatesChanged(templatesState: ICourseProductTemplateState): void {
    if (!templatesState.isLoading) {
      this.settingsTitle = 'Settings';

      this.courseProductTemplate = templatesState.templates.find(x => x.productCode === this.productCode);
      this.productResultsTemplate = templatesState.resultTemplates.find((t: ICourseProductTemplate) => t.productCode === this.productCode);

      // No Course Product Template for this Product
      if (!this.courseProductTemplate || !this.productResultsTemplate) {
        // TODO Need to handle this since the rest of the stuff will fail
        return;
      }

      this.loadCustomCss();

      // Load the Module Information Used by Child Components
      this.courseProductInformation = this.scoresService.getCourseProductInformationFromTemplates(this.course.title, this.courseProductTemplate, this.productResultsTemplate);

      // Try to load everything...
      this.setcourseProductResults();

      // Check for the certificates
      this.courseProductTemplateProperties = this.courseProductTemplate.properties;
      if (this.courseProductTemplateProperties) {
        if (this.productCode.includes('CERT-') && !this.courseProductTemplateProperties?.hasSeenCertificationModal) {
          this.certificationModalVisible = true;
        }

        if (this.courseProductTemplateProperties.hasRanking) {
          this.showRankingTab = this.courseProductTemplateProperties.hasRanking;
        }
      }

      // Load the Template into the mimic-core state
      this.store.dispatch(setCourseProductTemplateWithoutInstance({ template: this.courseProductTemplate }));

      this.timeLimit = this.courseProductTemplate.modules.reduce(
        (acc: number, cptModule: ICourseProductTemplateModule) => acc += cptModule.properties?.timeLimit || 0, 0
      );
    }

  }

  private handleStudentEnrollments(enrollments: IEnrollmentState): void {
    if (!enrollments?.isLoading) {
      if (enrollments.isFaulted) {
        this.enrollmentsTitle = 'Enrollments (failed)';
        this.enrollmentsFailed = true;
        // Set to empty to stop the table loading animation
        this.enrolledStudents = [];

      } else {
        this.enrollmentsTitle = 'Enrollments';
        this.enrollmentsFailed = false;

        this.enrolledStudents = enrollments.students || [];
        this.store.dispatch(loadStudentSummary());
      }
    }
  }

  private handleInstructorResources(instructorResources: IInstructorResourceState): void {
    if (!instructorResources?.isLoading) {
      if (instructorResources?.isFaulted) {
        this.instructorResourceTitle = 'Resources (failed)';
        this.instructorResourceFailed = true;
      }
      else {
        this.instructorResourceTitle = 'Resources';
        this.instructorResourceFailed = false;
        this.showResourcesTab = true;
        this.instructorResources = instructorResources.resources;
        this.updateDefaultTab();
      }
    }
  }

  private handleScoreSummaryDataChanged(scoreData: any): void {

    if ((scoreData?.studentSummaryPages || []).length > 0) {

      this.scoresTitle = 'Student Results';
      this.scoresFailed = false;

      if (this.productCode) {
        // Set for current Product
        this.currentProductScoresSummary = scoreData.studentSummaryPages.find(s => s.productCode === this.productCode);
        // Try to load everything...
        this.setcourseProductResults();
      }
    }
  }

  private loadStudentLocations(): void {
    this.scoresService.getStudentLocations().subscribe((studentLocations: IStudentLocation[]) => {
      // Don't Fail just because locations failed
      if (!studentLocations) {
        this.studentLocations = [];
      } else {
        this.studentLocations = studentLocations;
      }
    });
  }

  private setcourseProductResults(): void {
    if (this.enrolledStudents && this.courseProductInformation && this.currentProductScoresSummary && this.loggedInUser.jwt) {
      this.courseProductResults = this.scoresService.buildStudentScoreSummaries(this.enrolledStudents,
        this.currentProductScoresSummary.students,
        this.studentLocations,
        this.courseProductInformation.modules,
        this.loggedInUser);
    }
  }

  private getProductUrl(courseCode: string, productCode: string): string {

    const isEdifyProduct = (productCode.indexOf('EFY-') >= 0 || productCode.indexOf('EDIFY-') >= 0);

    let productBaseUrl = '';

    if (isEdifyProduct) {

      productBaseUrl = this.edifyBaseUrl;

      if (this.locationService.isNext) {
        productBaseUrl = this.edifyBaseUrl.replace('//edifyapp.', '//edifyapp-next.');
      }

      return `${productBaseUrl}/${productCode}/${courseCode}`;
    }
    else {

      const urlConfig = this.mimicAppUrls?.find(x => x.productCode === productCode) || '';

      if (!urlConfig) {
        return '';
      }

      productBaseUrl = urlConfig.url;

      if (this.locationService.isNext) {
        productBaseUrl = urlConfig.url.replace('//mimicapp.', '//mimicapp-next.');
      }
      return `${productBaseUrl}/${courseCode}`;

    }
  }

  acceptCertificationModal(): void {
    this.courseProductTemplateProperties = {
      ...this.courseProductTemplateProperties,
      hasSeenCertificationModal: true
    };
    this.store.dispatch(setOnlyCourseSettings({
      productCode: this.productCode,
      courseSettings: this.courseProductTemplateProperties as ICourseSettings
    }));

    this.certificationModalVisible = false;
  }

  reload(): void {
    global.window.location.reload();
  }

  saveSimulationSettings(params: { timezone: string, scoreFormat: string, courseSettings: ICourseSettings, modulesSettings: IModuleSetting[], pageSettings: IPageSetting[], questionBankSettings: IQuestionBankSettings[], elementSettings: IElementSettings[] }) {
    this.store.dispatch(setProductTemplateSettings({
      productCode: this.courseProductTemplate.productCode,
      timezone: params.timezone,
      scoreFormat: params.scoreFormat,
      courseSettings: params.courseSettings,
      moduleSettings: params.modulesSettings,
      pageSettings: params.pageSettings,
      elementSettings: params.elementSettings
    }));

    this.store.dispatch(saveQuestionBankSettings({
      questionBankSettings: params.questionBankSettings
    }));
  }

  reloadResults() {
    this.studentLocations = null;
    this.currentProductScoresSummary = null;
    this.loadStudentLocations();
    this.store.dispatch(loadStudentSummary());
  }

  reloadEnrollments() {
    this.enrolledStudents = null;
    this.scoresTitle = 'Student Results (loading)';
    this.scoresFailed = false;
    this.store.dispatch(loadEnrollments());
  }

  reloadInstructorResources() {
    this.instructorResources = null;
    this.instructorResourceTitle = 'Resources (loading)';
    this.instructorResourceFailed = false;
    this.store.dispatch(loadInstructorResources());
  }

  private loadCustomCss(): void {
    const instructorCssUrl: string = this.courseProductTemplate?.properties?.instructorCssUrl;
    if (!instructorCssUrl) {
      return;
    }

    let link: HTMLLinkElement = document.getElementById('custom-css') as any;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = instructorCssUrl;
      link.id = 'custom-css';
      document.head.appendChild(link);
    } else {
      link.parentElement.removeChild(link);
      this.loadCustomCss();
    }
  }

  private setupFeatures() {

    this.featuresService.features$.subscribe(() => {
      // Enable or Disable Show Enrollments Tab | Future as feature flag
      this.showEnrollmentsTab = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.loggedInUser, 'showEnrollmentsTab');

      if (this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.loggedInUser, 'showResourcesTab')) {
        // Handle instructor Resourcs Loaded to change label...
        this.store.dispatch(loadInstructorResources());
        this.subscriptions.push(this.store.select(selectInstructorResources).subscribe(this.handleInstructorResources.bind(this)));
      }

      // feature flag to show the Settings Tab to available
      this.showSettingsTab = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.loggedInUser, 'showSettingsTab');

      // feature flag for the Display Scores for Students toggle on the Settings page
      this.showResultSummaryToggle = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.loggedInUser, 'showResultSummaryToggle');

      // feature flag for disabling settings after the course is linked to an lms
      this.syncWithLmsCourse = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.loggedInUser, 'syncWithLmsCourse');

      this.updateDefaultTab();

    });
  }

  private handleScorableItemsChange(scorableItems: IScorableItemsState) {

    if (!scorableItems) {
      return;
    }

    this.scorableItems = scorableItems;

  }

  private updateDefaultTab() {
    if (this.showEnrollmentsTab && this.showResourcesTab) {
      this.initialTabIndex = 4;
    } else if (this.showEnrollmentsTab || this.showResourcesTab) {
      this.initialTabIndex = 3;
    }
  }

}
