import { Injectable } from '@angular/core';
import { ICourseState } from '../../reducers/course/course.reducer';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IInstructorPortalConfiguration } from '../../models/app-config.model';
import { ICourse, IMyCourse } from '../../models/course.model';
import { selectCurrentCourse, selectInstructorPortalConfiguration } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  config: IInstructorPortalConfiguration;
  course: ICourseState;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    store.select(selectInstructorPortalConfiguration).subscribe(configuration => this.config = configuration);
    store.select(selectCurrentCourse).subscribe(currentCourse => this.course = currentCourse);
  }

  get(): Observable<ICourse> {

    return this.http
      .get<ICourse>(`${this.config.serviceUrls.courses}/${this.course.courseCode}`);
  }

  getMyCourses(): Observable<IMyCourse[]> {
    return this.http
      .get<IMyCourse[]>(`${this.config.serviceUrls.myCourses}/instructor-portal`); // TODO Update after the endpoint is finished
  }
}
