import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICourseProductTemplate, ICourseProductTemplateElement, IScore, ISimulationInstance, courseProductInstance, selectScoringData } from '@stukent/mimic-core';
import { NzMessageService } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { IScorableItemsState } from '../../../../models/scorable-items/scorable-items-state.model';
import { IScoringState } from '../../../../models/simulation/scores/scores.model';
import { ICourseProductInformation, IStudentScoreSummary, ISummaryModuleScore } from '../../../../models/simulation/scores/simulation-results';
import { getStudentSimInstance } from '../../../../reducers/core/core.actions';
import { setStudentModuleScores } from '../../../../reducers/scores/scores.actions';
import { resetSim, resetQuestionInstance, resetModuleScore, resetLocation, scoreModuleForStudent, resetScoresByLocationIds } from '../../../../reducers/simulation/simulation.actions';
import { ScoresService } from '../../../../services/scores/scores.service';

@Component({
  selector: 'app-simulation-scores-table',
  templateUrl: './simulation-scores-table.component.html',
  styleUrls: ['./simulation-scores-table.component.scss']
})
export class SimulationScoresTableComponent implements OnInit, OnDestroy, OnChanges {

  @Input() courseProductInformation: ICourseProductInformation;
  @Input() courseProductResults: IStudentScoreSummary[];
  @Input() courseProductTemplate: ICourseProductTemplate;
  @Input() scoreType: string;
  @Input() includeUnEnrolledResults: boolean;
  @Input() private searchValue: string;

  @Input() scorableItems: IScorableItemsState;

  @Input() studentScores: IStudentScoreSummary[];
  @Input() allScores: IScore<any>[];



  private subscriptions: Subscription[] = [];

  selectedStudentSummary: IStudentScoreSummary;

  // This is what is show in the table
  filteredStudentData: IStudentScoreSummary[];

  // Export Setup
  private csvGenerator: any; // d3-csv library

  // This iterationId-to-moduleId dictionary is used to map an iterationId to a moduleId.
  // It is used to identify the page scores (such as AB Testing iteration scores) that belong to a module.
  private iterationsByModule: { [iterationId: string]: string; } = {};

  // Reset Round Setup
  resetRoundModalVisible = false;
  resetRoundButtonText = 'Yes, Send Student to Previous Round and Reset Scores';

  // Reset Sim Instance
  resetStudentModuleInputs = false;

  private studentModuleScoresLoaded: boolean;
  private studentSimInstanceLoaded: boolean;

  showStudentResultsLoadingModal: boolean;
  showStudentResultsModal = false;
  showStudentScorableItemsModal = false;
  modalTitle: string;

  handleOverdue = false;
  expandSet = new Set<number>();

  weights: any;
  resultsElements: { elements: ICourseProductTemplateElement[], cacheKey: string; };

  simModules = [];

  dataLoading = true;

  productCode: string;
  courseCode: string;

  isEdifyProduct: boolean;
  isMimicProduct: boolean;

  constructor(
    private messageService: NzMessageService,
    private scoresService: ScoresService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.productCode = this.courseProductTemplate?.productCode;
    this.courseCode = this.courseProductTemplate?.courseCode;

    this.isEdifyProduct = this.productCode?.indexOf('EFY-') >= 0;

    this.isMimicProduct = !this.isEdifyProduct;

    // Handle Individual Student Instance Module Loaded
    this.subscriptions.push(this.store.select(courseProductInstance).subscribe(this.handleStudentSimInstanceLoaded.bind(this)));

    // Handle Individual Student Module Results Loaded
    this.subscriptions.push(this.store.select(selectScoringData).subscribe(this.handleStudentModuleResultsLoaded.bind(this)));

    if (this.courseProductInformation) {
      this.simModules = this.courseProductInformation.modules;
    }

    // Set the default results
    this.filterStudents();

    this.setIterationsByModule();

    if (this.courseProductResults) {
      //  Add a show popover boolean
      this.courseProductResults.forEach(s => {
        s.moduleScores.map(sc => sc.popOverShown = false);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.courseProductResults) {
      this.dataLoading = this.courseProductResults ? false : true;
      this.filterStudents();

    }

    if (changes.includeUnEnrolledResults) {
      if (changes.includeUnEnrolledResults.currentValue !== changes.includeUnEnrolledResults.previousValue) {
        this.filterStudents();
      }
    }
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  private handleStudentModuleResultsLoaded(results: IScoringState): void {
    // We don't actually need to do anything here since the Components get the data they need from the store
    if (this.selectedStudentSummary && (results?.selectedModuleId === this.selectedStudentSummary.selectedModuleId)) {
      this.studentModuleScoresLoaded = true;
      this.loadStudentModuleResults();
    }
  }

  private handleStudentSimInstanceLoaded(results: ISimulationInstance): void {
    // We don't actually need to do anything here since the Components get the data they need from the store
    if (this.selectedStudentSummary && (!results?.isLoading && results?.id === this.selectedStudentSummary?.simulationInstanceId)) {
      this.studentSimInstanceLoaded = true;
      this.loadStudentModuleResults();
    }
  }

  private loadStudentModuleResults(): void {
    // Needed data is Loaded in to the store, show the reults Modal
    if (this.studentModuleScoresLoaded && this.studentSimInstanceLoaded) {
      this.showStudentResultsLoadingModal = false;
      this.showStudentResultsModal = true;
    }
  }

  private setIterationsByModule(): void {
    // Populate iterationsByModule, which is a iterationId-to-moduleId dictionary is used to map an iterationId to a moduleId.
    // It is used to identify the page scores (such as AB Testing iteration scores) that belong to a module.
    const iterationsByModuleObj = {};

    if (!this.courseProductTemplate?.modules) {
      this.iterationsByModule = iterationsByModuleObj;
      return;
    }

    for (const mod of this.courseProductTemplate.modules) {
      if (!mod.pages) {
        continue;
      }
      for (const page of mod.pages) {
        if (!page.iterations) {
          continue;
        }
        for (const iteration of page.iterations) {
          if (iteration && iteration.id) {
            iterationsByModuleObj[iteration.id] = mod.id;
          }
        }
      }
    }
    this.iterationsByModule = iterationsByModuleObj;
  }

  filterStudents() {

    this.filteredStudentData = [];

    if (!this.courseProductResults) {
      return;
    }

    const searchValue = this.searchValue?.toLowerCase();
    let tempResults: IStudentScoreSummary[] = [];

    if (searchValue?.length > 2) {

      this.courseProductResults.forEach(s => {
        let wasFound = false;
        if (s.firstName.toLowerCase().startsWith(searchValue)) {
          wasFound = true;
        } else if (s.lastName.toLowerCase().startsWith(searchValue)) {
          wasFound = true;
        } else if (s.identifier.toLowerCase().startsWith(searchValue)) {
          wasFound = true;
        }

        if (wasFound) {
          tempResults.push(s);
        }
      });

    } else {
      tempResults = this.courseProductResults;
    }

    if (!this.includeUnEnrolledResults && tempResults) {
      // Filter out UnEnrolled Students
      tempResults = tempResults.filter(r => r.isEnrolled);
    }

    this.filteredStudentData = tempResults;

  }

  sort(sort: { key: string, value: string; }): void {

    // default sort order is by name
    if (sort.value === null) {
      sort.key = 'displayName';
      sort.value = 'ascend';
    }

    // Use a Temp Array to avoid change detection issues
    const tempArray = JSON.parse(JSON.stringify(this.filteredStudentData));

    if (sort.key === 'displayName') {
      // Sort by Name
      if (sort.value === 'ascend') {
        tempArray.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
      } else if (sort.value === 'descend') {
        tempArray.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? -1 : 1);
      }

    } else if (sort.key === 'totalPercent') {
      // Sort by total
      if (sort.value === 'ascend') {
        tempArray.sort((a, b) => a.totalPointsEarned > b.totalPointsEarned ? 1 : -1);
      } else if (sort.value === 'descend') {
        tempArray.sort((a, b) => a.totalPointsEarned > b.totalPointsEarned ? -1 : 1);
      }

    } else {
      // Sort by Round Score

      if (sort.value === 'ascend') {
        tempArray.sort((a, b) => a.roundsDictionary[sort.key].score > b.roundsDictionary[sort.key].score ? 1 : -1);
      } else if (sort.value === 'descend') {
        tempArray.sort((a, b) => a.roundsDictionary[sort.key].score > b.roundsDictionary[sort.key].score ? -1 : 1);
      }

    }

    this.filteredStudentData = tempArray;

  }

  nameSearch(student: IStudentScoreSummary): string {

    if (this.searchValue && this.searchValue.length > 0) {
      const name = `${student.firstName} ${student.lastName}`.toLowerCase();
      const searchValue = this.searchValue.toLowerCase();
      return (name.includes(searchValue) || student.identifier.includes(searchValue)) ? 'visible' : 'hidden';
    }
  }

  // MODALS
  openStudentResultsModal(studentSummary: IStudentScoreSummary, round: ISummaryModuleScore): void {
    const studentIdentifier = studentSummary.identifier;

    if (!round?.hasResults) {
      return;
    }

    // Set the Elements from the Results Modules
    const simModule = this.courseProductInformation.modules.find(sm => sm.moduleId === round.id);

    // Give it a nice title...
    this.modalTitle = `${studentSummary.firstName} ${studentSummary.lastName}: ${round.name} | ${simModule.title}`;

    // Pop the results loading modal.
    // the actual modal is show after the data is loaded
    this.showStudentResultsLoadingModal = true;

    simModule.simElements.cacheKey = Math.random().toString();
    this.resultsElements = simModule.simElements;

    this.weights = simModule.weights;

    this.selectedStudentSummary = studentSummary;
    this.selectedStudentSummary.selectedModuleId = round.id;

    // Reset the Loading Flags
    this.studentModuleScoresLoaded = false;
    this.studentSimInstanceLoaded = false;

    this.store.dispatch(getStudentSimInstance({
      studentIdentifier: studentSummary.identifier,
      productCode: this.courseProductInformation.productCode
    }));

    const filteredScores = this.allScores.filter(s => s.context.studentIdentifier === studentSummary.identifier);

    this.store.dispatch(setStudentModuleScores({ scores: filteredScores, moduleId: this.selectedStudentSummary.selectedModuleId, iterationsByModule: this.iterationsByModule }));

  }

  handleCloseStudentResultsModal(): void {

    this.selectedStudentSummary.selectedModuleId = null;
    this.showStudentResultsModal = false;
    this.studentModuleScoresLoaded = false;
    this.studentSimInstanceLoaded = false;

  }

  confirmResetRound(student: IStudentScoreSummary): void {
    this.selectedStudentSummary = student;
    this.resetRoundModalVisible = true;
  }

  cancelResetRound(): void {

    this.resetStudentModuleInputs = false;

    this.resetRoundModalVisible = false;
    this.selectedStudentSummary = null;
  }

  public resetRound(): void {

    const student = this.selectedStudentSummary;

    const roundInformation = this.courseProductInformation.modules.find(m => m.moduleId === student.lastScoredRound.id);

    if (this.resetStudentModuleInputs) {
      // Resets simulation instance
      this.createMessage('success', `Round for ${student.displayName} is being reset.`);

      this.store.dispatch(resetSim({
        studentIdentifier: student.identifier, moduleId: student.lastScoredRound.id
      }));

      // Finds question bank Ids
      const questionBankIdsToReset = roundInformation?.questionBankIds ?? [];

      if (questionBankIdsToReset.length > 0) {
        questionBankIdsToReset.forEach((questionBankId) => {
          this.store.dispatch(resetQuestionInstance({
            studentIdentifier: student.identifier,
            questionBankId,
            userInstanceCode: student.simulationInstanceId
          }));
        });
      }
    } else {
      this.createMessage('success', `Scores for ${student.displayName} are being reset.`);
    }

    // Resets the round score
    if (student.simulationInstanceId) {
      this.store.dispatch(resetModuleScore({
        simulationInstanceId: student.simulationInstanceId, moduleId: student.lastScoredRound.id
      }));
    }

    // Reset the score for any "iterations"
    const locationIds = [];

    for (const mod of this.courseProductTemplate.modules) {
      if (mod.id === roundInformation.moduleId && mod.pages) {
        for (const page of mod.pages) {
          if (page.iterations && page.iterations.length > 0) {
            for (const iteration of page.iterations) {
              if (iteration && iteration.id) {
                locationIds.push(iteration.id);
              }
            }
          }
        }
      }
    }

    if (locationIds.length > 0) {
      this.store.dispatch(resetScoresByLocationIds({
        simulationInstanceId: student.simulationInstanceId, locationIds
      }));
    }
    // Done resetting pages



    // Don't Defualt this flag
    this.resetStudentModuleInputs = false;

    // Changes location
    const resetToPageId = roundInformation.firstPageIdOfModule;

    // Set the Previous Module to the current Location
    const prevousModule = student.moduleScores.find(m => m.id === student.lastScoredRound.id);
    prevousModule.isCurrentLocation = true;

    if (resetToPageId) {
      this.store.dispatch(resetLocation({
        studentIdentifier: student.identifier, moduleId: student.lastScoredRound.id, pageId: resetToPageId, instanceId: ''
      }));
    }

    // Updates the student scores table
    this.removeModuleFromStudentData(student.simulationInstanceId, student.lastScoredRound.id);

    student.lastScoredRound = this.scoresService.getLastScoredRound(student.moduleScores);

    // TODO Get this from a Redux outcome for reset...
    this.resetRoundModalVisible = false;
  }

  private removeModuleFromStudentData(simulationInstanceId: string, moduleId: string) {
    if (this.courseProductResults.length > 0) {
      this.courseProductResults.forEach((s) => {
        if (s.simulationInstanceId === simulationInstanceId) {
          s.moduleScores = s.moduleScores.filter(m => m.id !== moduleId);
        }
      });
    }
  }

  scoreStudentModule(student: IStudentScoreSummary, moduleId: string): void {

    if (!student.simulationInstanceId || !moduleId) {
      return;
    }

    this.store.dispatch(scoreModuleForStudent({ studentIdentifier: student.identifier, moduleId }));

    const pageId = this.courseProductInformation.modules.find(m => m.moduleId === moduleId).firstPageIdOfModule;

    this.store.dispatch(resetLocation({
      studentIdentifier: student.identifier,
      moduleId,
      pageId,
      instanceId: ''
    }));
  }

  private createMessage(type: string, message: string, duration?: number): void {
    this.messageService.create(type, message, { nzDuration: duration || 3000 }); // Default duration is 3 seconds
  }

  scoreRightClick(student: IStudentScoreSummary, round: ISummaryModuleScore) {
    round.popOverShown = true;
    return false;
  }

  manageStudentScorableItems(student: IStudentScoreSummary) {
    this.selectedStudentSummary = student;
    this.showStudentScorableItemsModal = true;

    this.store.dispatch(getStudentSimInstance({
      studentIdentifier: student.identifier,
      productCode: this.courseProductInformation.productCode
    }));
  }

  handleCloseStudentScorableItemsModal() {
    this.selectedStudentSummary = null;
    this.showStudentScorableItemsModal = false;
  }
  trackByStudentId(id: number) {
    return id;
  }
}

