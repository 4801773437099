import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QuestionBankScorableItemsService } from '../../services/scorable-items/question-bank-scorable-items.service';
import { ScorableItemsService } from '../../services/scorable-items/scorable-items.service';
import { loadStudentsScores, loadStudentSummary } from '../scores/scores.actions';
import {
  loadProductScoringConfiguration, loadQuestionBankSettings, resetScorableItem,
  saveQuestionBankSettings, setQuestionBankSettings, setScorableItemConfiguration, startStopDueDateTimers
} from './scorable-items.actions';

@Injectable()
export class CourseProductSettingsEffects {
  loadQuestionBankSettings$ = createEffect(() => this.actions$.pipe(
    ofType(loadQuestionBankSettings),
    mergeMap(() =>
      this.questionBankScorableItemsService.getQuestionBankConfiguration()
        .pipe(
          map(questionBankSettings => setQuestionBankSettings({ questionBankSettings })),
          catchError(() => [])
        ))
  ));

  saveQuestionBankSettings$ = createEffect(() => this.actions$.pipe(
    ofType(saveQuestionBankSettings),
    mergeMap(({ questionBankSettings }) =>
      this.questionBankScorableItemsService.saveQuestionBankConfiguration(questionBankSettings)
        .pipe(
          catchError((err) => {
            console.error('ERROR', err);
            return EMPTY;
          })
        )
    )
  ), { dispatch: false });

  loadProductScoringConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(loadProductScoringConfiguration),
    mergeMap(() => this.scorableItemsService.getScorableItemsConfiguration()
      .pipe(
        map(config => setScorableItemConfiguration({ config })),
        catchError((err) => {
          console.error('ERROR', err);
          return EMPTY;
        })
      )
    )
  ));

  resetScorableItem$ = createEffect(() => this.actions$.pipe(
    ofType(resetScorableItem),
    mergeMap(({ scorableItem }) => this.scorableItemsService.resetScorableItem(scorableItem)
      .pipe(
        mergeMap(() => {
          return [
            loadQuestionBankSettings(),
            loadProductScoringConfiguration(),
            loadStudentSummary(),
            loadStudentsScores({
              simulationInstanceId: scorableItem.persistedScore.context.simulationInstanceId,
              moduleId: scorableItem.persistedScore.context.simulationInstanceLocationId,
              iterationsByModule: null
            })
          ];
        }),
        catchError((err) => {
          console.error('ERROR', err);
          return EMPTY;
        })
      )
    )
  ));

  startStopDueDateTimers$ = createEffect(() => this.actions$.pipe(
    ofType(startStopDueDateTimers),
    mergeMap(({ timers }) =>
      this.questionBankScorableItemsService.startStopDueDateTimers(timers)
        .pipe(
          catchError((err) => {
            console.error('ERROR', err);
            return EMPTY;
          })
        )
    )
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private questionBankScorableItemsService: QuestionBankScorableItemsService,
    private scorableItemsService: ScorableItemsService
  ) { }
}

