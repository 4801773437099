import { Component, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { StudentEnrollmentsTableComponent } from './student-enrollments-table/student-enrollments-table.component';
import { IStudent } from '../../models/student.model';

@Component({
  selector: 'app-student-enrollments',
  templateUrl: './student-enrollments.component.html',
  styleUrls: ['./student-enrollments.component.scss']
})
export class StudentEnrollmentsComponent
  implements AfterViewInit {

  @Input() enrolledStudents: IStudent[];
  @Output() updateResults = new EventEmitter();

  // Get view children to send stuff to and from
  @ViewChild('search') private search: ElementRef;
  @ViewChild(StudentEnrollmentsTableComponent) private enrollmentTable: StudentEnrollmentsTableComponent;

  searchValue = '';
  // Used to control if my own results are shown
  unEnrolledStudentsAreShown = false;

  ngAfterViewInit() {
    // Debounce the search
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.enrollmentTable.filterStudents();
        })
      ).subscribe();
  }

}
