import { Action, createReducer, on } from '@ngrx/store';
import { setLinkedCourseProducts } from './lms-integration.actions';

export interface ILmsIntegrationState {
    isCourseLinked: boolean;
    linkedProducts: string[];
}

export const initialState: ILmsIntegrationState = {
    isCourseLinked: false,
    linkedProducts: []
};

const reducer = createReducer(initialState,
    on(setLinkedCourseProducts, (state, { linkedProducts }) => {
        return {
            ...state,
            isCourseLinked: !!linkedProducts.length,
            linkedProducts
        };
    })
);

export function lmsIntegrationReducer(lms: ILmsIntegrationState, action: Action) {
    return reducer(lms, action);
}
