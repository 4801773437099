import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { IResourceState } from '../../../models/resources/instructor-resource.model';
import { InstructorResourcesService } from '../../../services/instructor-resources/instructor-resources.service';

@Component({
  selector: 'app-instructor-resource',
  templateUrl: './instructor-resource.component.html',
  styleUrls: ['./instructor-resource.component.scss']
})
export class InstructorResourceComponent implements OnDestroy {

  @Input() resource: IResourceState;

  constructor(private instructorResourceService: InstructorResourcesService) { }

  previewPdf(): void {
    this.instructorResourceService.downloadFile(this.resource.path).subscribe(file => {
      if (!file || file instanceof HttpErrorResponse) {
        return;
      }

      const fileBlob = new Blob([file], { type: 'application/pdf' });
      const fileUrl = URL.createObjectURL(fileBlob);

      const a = document.createElement('a');
      a.target = '_blank';
      a.href = fileUrl;
      a.click();
      document.body.removeChild(a);
    });
  }

  downloadImage() {
    this.instructorResourceService.downloadFile(this.resource.path).subscribe(file => {
      if (!file || file instanceof HttpErrorResponse) {
        return;
      }
      this.download(file);
    });
  }


  private download(file: Blob): void {
    const fileName = `${this.resource.title}.${this.resource.path.substring(this.resource.path.lastIndexOf('.') + 1)}`;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result + '';
      const a = document.createElement('a');
      a.setAttribute('download', `${fileName}`);
      a.href = 'data:text/plain;charset=utf-8;base64,' + base64data.split('base64,')[1];
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.parentElement.removeChild(a);
    };
  }

  ngOnDestroy(): void {
  }
}
