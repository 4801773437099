import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureArea, FeatureService } from '@stukent/feature-toggle';
import { IProfileState, IUser } from '@stukent/user';
import { Subscription } from 'rxjs';
import { ITemplateVersion } from '../../models/tempate-versions.model';
import { loadVersionInformation, upgradeTemplate } from '../../reducers/templateVersions/templateVersions.actions';
import { ITemplateVersionsState } from '../../reducers/templateVersions/templateVersions.reducer';

@Component({
  selector: 'app-course-product-template-version',
  templateUrl: './course-product-template-version.component.html',
  styleUrls: ['./course-product-template-version.component.scss'],
})
export class CourseProductTemplateVersionComponent implements OnInit, OnDestroy {

  allowTemplateUpgradeEnabled = false;

  private subscriptions: Subscription[] = [];
  private user: IUser;
  updatedVersions: ITemplateVersion[];

  constructor(
    private featuresService: FeatureService,
    private store: Store<{ profile: IProfileState, templateVersions: ITemplateVersionsState; }>) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(x => x.profile?.user).subscribe(user => {
      if (user?.jwt?.length > 0) {
        this.user = user;
        this.setupFeatures();
      }
    }));

    this.subscriptions.push(this.store.select(x => x.templateVersions).subscribe(versionState => {
      if (versionState?.areLoaded) {
        this.updatedVersions = versionState.versions;
      } else {
        // load them
        this.store.dispatch(loadVersionInformation({ productCode: '' }));
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  private setupFeatures(): void {
    this.subscriptions.push(this.featuresService.features$.subscribe(() => {
      // Enable or Disable Template Upgrades | Future as feature flag
      this.allowTemplateUpgradeEnabled = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.user, 'allowTemplateUpgrade');

    }));
  }

  upgradeTemplate(): void {
    this.store.dispatch(upgradeTemplate({ productCode: '', versions: this.updatedVersions }));
  }
}
