import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureArea, FeatureService } from '@stukent/feature-toggle';
import { IProfileState, IUser } from '@stukent/user';
import { Subscription } from 'rxjs';
import { getLmsEnrollments } from '../../reducers/enrollment/enrollment.actions';
import { LmsIntegrationService } from '../../services/integration/lms/lmsIntegration.service';

@Component({
  selector: 'app-get-enrollment-button',
  templateUrl: './get-enrollment-button.component.html',
})
export class GetEnrollmentButtonComponent implements OnInit, OnDestroy {

  isLinkedToLMS = false;
  insLMSIntegrationEnabled = false;

  private subscriptions: Subscription[] = [];
  private user: IUser;

  constructor(
    private featuresService: FeatureService,
    private lmsIntegrationService: LmsIntegrationService,
    private store: Store<{ profile: IProfileState }>) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(x => x.profile.user).subscribe(user => {
      if (user?.jwt.length > 0) {
        this.user = user;
        this.setupFeatures();
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  getLMSEnrollments(): void {
    this.store.dispatch(getLmsEnrollments());
  }

  private setupFeatures(): void {
    this.subscriptions.push(this.featuresService.features$.subscribe(() => {
      // Enable or Disable LMS Integration | Future as feature flag
      this.insLMSIntegrationEnabled = this.featuresService.isFeatureIsEnabled(FeatureArea.instructorPortal, this.user, 'enableLMSIntegration');

      if (this.insLMSIntegrationEnabled) {
        this.subscriptions.push(this.lmsIntegrationService.getCourse().subscribe(x => this.isLinkedToLMS = x.lmsEnabled));
      }
    }));
  }
}
