import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { CourseProductTemplateService } from '../../services/course-product-template/course-product-template.service';
import {
  loadCourseProductTemplate,
  setCourseProductTemplate,
  loadResultsTemplate,
  setResultsTemplate,
  setOnlyCourseSettings, setPageSettings, setCourseSettings, setModuleSettings, setProductTemplateSettings
} from './template.actions';

@Injectable()
export class TemplateEffects {

  constructor(
    private actions$: Actions,
    private courseProductTemplateService: CourseProductTemplateService
  ) {
  }

  loadCourseProductTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(loadCourseProductTemplate),
    mergeMap(() =>
      this.courseProductTemplateService.get()
        .pipe(
          map(template => setCourseProductTemplate({ template })),
          catchError(() => EMPTY)
        )
    )
  ));

  loadResultsTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(loadResultsTemplate),
    mergeMap(() =>
      this.courseProductTemplateService.getResultsTemplate()
        .pipe(
          map(template => setResultsTemplate({ template })),
          catchError(() => EMPTY)
        )
    )
  ));

  setModuleSettings$ = createEffect(() => this.actions$.pipe(
    ofType(setModuleSettings),
    mergeMap(({ productCode, moduleSettings }) =>
      this.courseProductTemplateService.setModuleSettings(productCode, moduleSettings)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  setCourseSettings$ = createEffect(() => this.actions$.pipe(
    ofType(setCourseSettings),
    mergeMap(({ productCode, courseSettings }) =>
      this.courseProductTemplateService.setCourseSettings(productCode, courseSettings)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  // create set page settings effect that listens to setPageSettings action
  setPageSettings$ = createEffect(() => this.actions$.pipe(
    ofType(setPageSettings),
    mergeMap(({ productCode, pageSettings }) =>
      this.courseProductTemplateService.setPageSettings(productCode, pageSettings)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  setProductTemplateSettings$ = createEffect(() => this.actions$.pipe(
    ofType(setProductTemplateSettings),
    mergeMap(({ productCode, timezone, scoreFormat, courseSettings, moduleSettings, pageSettings, elementSettings }) =>
      this.courseProductTemplateService.setProductTemplateSettings(productCode, timezone, scoreFormat, courseSettings, moduleSettings, pageSettings, elementSettings)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  setOnlyCourseSettings$ = createEffect(() => this.actions$.pipe(
    ofType(setOnlyCourseSettings),
    mergeMap(({ productCode, courseSettings }) =>
      this.courseProductTemplateService.setCourseSettings(productCode, courseSettings)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

}
