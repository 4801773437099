import { createReducer, on, Action } from '@ngrx/store';
import { IInstructorResourceState } from '../../models/resources/instructor-resource.model';
import { setInstructorResources, setInstructorResourcesFailed } from './instructor-resource.actions';


export const initialState: IInstructorResourceState = {
  resources: null,
  isLoading: true,
  isFaulted: false,
};

const reducer = createReducer(initialState,
  on(setInstructorResources, (state, { instructorResources }) => {
    if (instructorResources) {
      return { ...state, resources: instructorResources.resources, isLoading: false, isFaulted: false, errorMessage: null };
    } else {
      return { ...state, resources: [], isLoading: false, isFaulted: false, errorMessage: null };
    }
  }),
  on(setInstructorResourcesFailed, (state, { errorMessage }) => {
    return { ...state, errorMessage, isLoading: false, isFaulted: true };
  })
);

export function instructorResourceReducer(instructorResources: IInstructorResourceState, action: Action) {
  return reducer(instructorResources, action);
}
