import { createSelector } from '@ngrx/store';

export const scoresFeature = scores => scores.scores as any;

export const selectScores = createSelector(
  scoresFeature,
  baseInstructorPortalState => baseInstructorPortalState
);

export const selectAllScores = createSelector(
  scoresFeature,
  baseInstructorPortalState => baseInstructorPortalState.allScores
);

