import { createReducer, on, Action } from '@ngrx/store';
import { IScoresState } from 'src/app/models/simulation/scores/scores.model';
import { setStudentModuleScores, setStudentSummary, clearStudentModuleScore, setAllScores } from './scores.actions';

export const initialState: IScoresState = {
  studentSummaryPages: null,
  simulationInstanceId: '',
  scoring: {
    selectedModuleId: '',
    scores: {
      moduleScores: null
    }
  },
  allScores: [],
  isLoading: true
};

const reducer = createReducer(initialState,
  on(setStudentSummary, (state, { summary }) => {
    const newState = JSON.parse(JSON.stringify(state));

    newState.studentSummaryPages = [summary];
    newState.isLoading = false;

    return newState;
  }),
  on(setStudentModuleScores, (state, { scores, moduleId, iterationsByModule }) => {
    let newState = JSON.parse(JSON.stringify(state));

    if (!scores?.length) {
      newState = JSON.parse(JSON.stringify(initialState));
      newState.isLoading = false;

      return newState;
    }

    const moduleScoreIds: string[] = [];

    for (const s of scores) {
      if (s.context.simulationInstanceLocationType === 'module' && !moduleScoreIds.includes(s.context.simulationInstanceLocationId)) {
        moduleScoreIds.push(s.context.simulationInstanceLocationId);
      }
    }

    const moduleScores = moduleScoreIds.map(id => {
      return {
        moduleId: id,
        scores: scores.filter(s =>
          (s.context.simulationInstanceLocationType === 'module' && s.context.simulationInstanceLocationId === id) ||
          (s.context.simulationInstanceLocationType === 'page' && iterationsByModule[s.context.simulationInstanceLocationId] === id))
      };
    });

    const scoring = {
      scores: {
        moduleScores: moduleScores || []
      },
      selectedModuleId: moduleId,
    };



    newState.scoring = scoring;
    newState.isLoading = false;

    return newState;
  }),
  on(clearStudentModuleScore, (state) => {
    const newState = JSON.parse(JSON.stringify(state));

    newState.scoring = {
      selectedModuleId: '',
      scores: {
        moduleScores: []
      }
    };
    return newState;
  }),
  on(setAllScores, (state, { allScores }) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.allScores = allScores;
    return newState;
  })
);

export function scoresReducer(scores: IScoresState, action: Action) {
  return reducer(scores, action);
}
