import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-information',
  templateUrl: './setup-information.component.html',
  styleUrls: ['./setup-information.component.scss']
})
export class SetupInformationComponent implements OnInit {

  @Input() setupInformation: string;

  constructor() { }

  ngOnInit() {
  }

}
